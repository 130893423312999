import styled from "styled-components";
import { getProgress } from "../../helpers/tripHelpers";
import { TRIP_STATE } from "../../constants/trip";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "antd";

const TripStatus = ({ status }) => {
	const { PAYMENT_PENDING } = TRIP_STATE;

	return (
		<Tooltip
			title={
				status === PAYMENT_PENDING
					? "Please make payment with the 'Pay Now' button beside or check your email for a message from Paystack to make payment before your trip can start"
					: ""
			}
			overlayInnerStyle={{
				background: "#000",
				borderRadius: "12px",
				padding: "10px",
				fontSize: "12px",
			}}
		>
			<Status className="min-w-fit" status={status}>
				{status === PAYMENT_PENDING && <IoIosInformationCircleOutline size={14} />}
				{getProgress(status).title}
			</Status>
		</Tooltip>
	);
};

const Status = styled.span`
	display: flex;
	align-items: center;
	gap: 4px;

	width: min-content;
	white-space: nowrap;
	padding: 6px 12px;
	text-align: center;
	border-radius: 10px;
	font-weight: 500;
	font-size: 12px;
	color: #047a48;
	background: #f3f4f7;
	color: ${({ status }) => status === TRIP_STATE.ON_ROUTE && "#047a48"};
	color: ${({ status }) => status === TRIP_STATE.PENDING && "#cdaa7d"};
	color: ${({ status }) => status === TRIP_STATE.FLAGGED && "#344055"};
	color: ${({ status }) => status === TRIP_STATE.FINISHED && "#047a48"};
	color: ${({ status }) => status === TRIP_STATE.CANCELLED && "#b42219"};
	color: ${({ status }) => status === TRIP_STATE.ACCEPTED && "#197eb4"};
	color: ${({ status }) => status === TRIP_STATE.PAYMENT_PENDING && "#191eb4"};
	background: ${({ status }) => status === TRIP_STATE.FLAGGED && "#f3f4f7"};
	background: ${({ status }) => status === TRIP_STATE.PENDING && "#fff8ef"};
	background: ${({ status }) => status === TRIP_STATE.ON_ROUTE && "#ebfdf3"};
	background: ${({ status }) => status === TRIP_STATE.FINISHED && "#ebfdf3"};
	background: ${({ status }) => status === TRIP_STATE.CANCELLED && "#fdf3f3"};
	background: ${({ status }) => status === TRIP_STATE.ACCEPTED && "#f3f8fd"};
	background: ${({ status }) => status === TRIP_STATE.PAYMENT_PENDING && "#f3e2fd"};

	.space {
		margin: 0 5px;
	}
`;

export default TripStatus;
