import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ChangePassword from "./ChangePassword";
import PaymentMethod from "./PaymentMethod";
import usePageTitle from "../../../components/hooks/usePageTitle";
import Profile from "./Profile";

const { TabPane } = Tabs;

const Settings = () => {
	const [active, setActive] = useState("profile");

	usePageTitle("Settings");

	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tab = searchParams.get("tab");

	const handleChange = (key) => {
		setActive(key);
		searchParams.set("tab", key);
		navigate({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	useEffect(() => {
		tab && setActive(tab);
	}, [tab]);

	return (
		<StyledSettings>
			<Inner>
				<h1>Settings</h1>
				<Tabs defaultActiveKey={active} activeKey={active} onChange={handleChange}>
					<TabPane tab="Profile" key="profile">
						<Profile />
					</TabPane>
					<TabPane tab="Payment Method" key="payment">
						<PaymentMethod />
					</TabPane>
					<TabPane tab="Account Management" key="account">
						<ChangePassword />
					</TabPane>
				</Tabs>
			</Inner>
		</StyledSettings>
	);
};

const StyledSettings = styled.div`
	padding: 60px 0;
`;

const Inner = styled.div`
	width: 80%;
	margin: 0 auto;

	@media (max-width: 500px) {
		width: 90%;
	}
`;

export default Settings;
