import styled from "styled-components";
import { Button, InputNumber, Modal } from "antd";
import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const PlaceBidModal = ({
	visible,
	closeModal,
	handleSubmit,
	loading,
	title,
	description,
	setData,
	data,
}) => {
	return (
		<CustomStyledModal
			visible={visible}
			width={600}
			centered
			onCancel={closeModal}
			footer={""}
			closable={false}
			maskClosable={false}
		>
			<div className="lg:p-2 rounded-lg flex flex-col items-center text-center">
				<div>
					<h2 className="text-2xl my-0">{title}</h2>
					<p className="lg:px-6 text-[#667085] mb-0">{description}</p>
				</div>

				<div className="w-full text-left lg:px-3 my-6 lg:my-8">
					<label className="text-base font-[500]">Enter your bid</label>
					<div className="relative bg-[#f7f7f7] w-full border px-4  mt-2 flex items-center">
						<span className="font-[500]">N</span>{" "}
						<InputNumber
							name="bid_price"
							value={data?.bid_price}
							formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							onChange={(value) => setData({ ...data, bid_price: value })}
							placeholder="Enter you bid"
							controls={false}
							className="custom-input-number"
							inputStyle={{
								outline: "none",
								border: "none",
							}}
							style={{
								width: "100%",
								height: "100%",
								background: "none",
								border: "none",
								outline: "none",
								padding: "2px 0",
								":focus": {
									outline: "none",
									border: "none",
								},
							}}
						/>
					</div>
				</div>

				<div className="flex gap-3 items-center w-full lg:px-3">
					<Button className="w-full" onClick={closeModal} size={"large"}>
						Cancel
					</Button>
					<Button
						className="w-full"
						type={"primary"}
						size={"large"}
						onClick={handleSubmit}
					>
						{loading ? (
							<ScaleLoader
								color={"#ffffff"}
								loading={loading}
								size={100}
								width={5}
								margin={3}
								height={15}
							/>
						) : (
							"Submit"
						)}
					</Button>
				</div>
			</div>
		</CustomStyledModal>
	);
};

const CustomStyledModal = styled(Modal)`
	.border {
		border: 1px solid rgba(102, 112, 133, 0.65);
		border-radius: 4px;
	}
`;

export default PlaceBidModal;
