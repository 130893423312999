import React from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import ScaleLoader from "react-spinners/ScaleLoader";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { TRIP_STATE, serviceCharge } from "../../constants/trip";
import { BID_DECISION_OPTIONS } from "../../helpers/tripHelpers";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";
import currency from "currency.js";

const AcceptBidModal = ({ visible, closeModal, data, makeBidDecision, bid_decision_loading }) => {
	const navigate = useNavigate();

	const bidDetail = data?.bid;
	const tripDetail = data?.trip;

	const { ACCEPT_BID } = BID_DECISION_OPTIONS;

	const requiresConfirmation = () =>
		bidDetail?.accepted && bidDetail?.tripState === TRIP_STATE.PENDING;

	const acceptBid = async () => {
		try {
			const res = await makeBidDecision({
				tripId: tripDetail?.id,
				bidId: bidDetail?.id,
				decision: ACCEPT_BID,
			});

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
			toast.success(
				requiresConfirmation() ? "Bid confirmed successfully" : "Bid accepted successfully",
			);
			navigate("/app/trips/upcoming", { replace: true });
		} catch (e) {
			toast.error(e.message || "An error occured, please try again");
			return e;
		}
	};

	const vat = () => {
		return (7.5 / 100) * (+bidDetail?.bid_price + serviceCharge);
	};

	const total = vat() + +bidDetail?.bid_price + serviceCharge;
	return (
		<CustomStyledModal
			visible={visible}
			width={900}
			centered
			onCancel={closeModal}
			footer={""}
			closable={false}
			maskClosable={false}
		>
			<div className="lg:p-2 rounded-lg flex flex-col md:flex-row">
				<div className="md:w-8/12 pb-4 md:pb-0 md:pr-4">
					<h3 className="text-2xl font-semibold mb-5">Trip Summary</h3>

					<div className="space-y-2">
						<TripPrimaryItem name="Haulage Company:" value={bidDetail?.company} bold />
						<TripPrimaryItem
							name="Pickup Location:"
							value={tripDetail?.start_address}
						/>
						<TripPrimaryItem
							name="Drop-off Location:"
							value={tripDetail?.end_address}
						/>
						{tripDetail?.drop_off_address.trim() && (
							<TripPrimaryItem
								name="Empties Drop-off Location:"
								value={tripDetail?.drop_off_address}
							/>
						)}
						<TripPrimaryItem name="Pickup Date:" value={tripDetail?.date} />
						{tripDetail?.container_id && (
							<TripPrimaryItem
								name="Container ID:"
								value={tripDetail?.container_id}
							/>
						)}
					</div>
				</div>

				<div className="md:w-4/12 pt-5 md:pt-0 md:pl-5 border-t-1 border-l space-y-6">
					<div>
						<div className="grid grid-cols-12 items-center gap-2">
							<p className="m-0 col-span-3">Price:</p>
							<p className="m-0 font-semibold col-span-9 text-right">
								{bidDetail?.rate}
							</p>
						</div>
						<div className="grid grid-cols-12 items-center gap-2">
							<p className="m-0 col-span-3">Charges:</p>
							<p className="m-0 font-semibold col-span-9 text-right">
								{currency(serviceCharge).format({ symbol: "₦" })}
							</p>
						</div>
						<div className="grid grid-cols-12 items-center gap-2">
							<p className="m-0 col-span-3">VAT:</p>
							<p className="m-0 font-semibold col-span-9 text-right">
								{currency(vat()).format({ symbol: "₦" })}
							</p>
						</div>
						<div className="grid grid-cols-12 items-center gap-2 border-t mt-3 pt-2">
							<p className="m-0 col-span-3">Total:</p>
							<p className="text-2xl m-0 font-semibold col-span-9 text-right">
								{currency(total).format({ symbol: "₦" })}
							</p>
						</div>
					</div>

					<div className="space-y-2 md:space-y-3">
						<Button
							className="w-full"
							type={"primary"}
							size={"large"}
							onClick={acceptBid}
						>
							{bid_decision_loading ? (
								<ScaleLoader
									color={"#ffffff"}
									loading={bid_decision_loading}
									size={100}
									width={5}
									margin={3}
									height={15}
								/>
							) : requiresConfirmation() ? (
								"Confirm Acceptance"
							) : (
								"Accept Bid"
							)}
						</Button>
						<Button
							className="w-full border-cancel"
							onClick={closeModal}
							size={"large"}
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		</CustomStyledModal>
	);
};

const CustomStyledModal = styled(Modal)`
	.border-l {
		border-left: 1px solid #e4e7ec;
	}
	.border-t {
		border-top: 1px solid #e4e7ec;
	}
	.border-t-0 {
		border-top: 0px;
	}

	.border-cancel {
		border: 1px solid #e27626;
	}

	@media (max-width: 768px) {
		.border-l {
			border-left: none;
		}

		.border-t-1 {
			border-top: 1px solid #e4e7ec;
		}
	}
`;

const TripPrimaryItem = ({ name, value, bold }) => {
	return (
		<div className="grid grid-cols-12">
			<p className="col-span-5 m-0">{name}</p>
			<p className={`col-span-7 m-0 ${bold && "font-semibold"}`}>{value}</p>
		</div>
	);
};

const mapStateToProps = ({ bid_decision_loading }) => ({ bid_decision_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(AcceptBidModal);
