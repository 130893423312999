import React, { useState } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import ScaleLoader from "react-spinners/ScaleLoader";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";

const DeleteAccountModal = ({ visible, closeModal, createLead, loading_lead, user }) => {
	const [comment, setComment] = useState("");

	const close = () => {
		setComment("");
		closeModal();
	};

	const _createLead = async (data) => {
		try {
			const res = await createLead(data);
			if (res?.error) {
				throw new Error("Opps, an error occured");
			}
			setComment("");
			closeModal();
			toast.success("Delete request submitted successfully");
		} catch (e) {
			toast.error("Opps, an error occured");
		}
	};

	const handleDelete = () => {
		if (!comment) return toast.error("Please enter reason for deleting");

		const payload = {
			first_name: user?.given_name,
			last_name: user?.family_name,
			phone: user?.phone_number,
			email: user?.email,
			business_type: "Regular User",
			lead_source: "Request to Delete Account",
			additional_information: comment,
		};

		_createLead(payload);
	};

	return (
		<CustomStyledModal
			visible={visible}
			width={600}
			centered
			onCancel={close}
			footer={""}
			closable={false}
			maskClosable={false}
		>
			<div className="lg:p-2 rounded-lg space-y-4">
				<h3 className="text-2xl font-semibold mb-3 pb-3 border-b">Delete Account</h3>

				<p>
					Deleting your account means permanently removing your profile and removing
					access to all your Go Truck information from our site. This action cannot be
					undone.
				</p>
				<p>
					We value your feedback, kindly leave us a comment why you want to permanently
					delete your account. If you have a question or request about your account that
					requires attention, please contact us{" "}
					<Link to="/contact" target="_blank">
						here
					</Link>
					.
				</p>

				<textarea
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					placeholder="Enter reason for deleting here"
					className="w-full p-3 rounded-md border-[#D0D5DD] outline-none focus:outline-none"
				></textarea>

				<div className="flex flex-col gap-4 sm:flex-row sm:gap-12 items-center justify-between">
					<Button className="w-full border-cancel" onClick={close} size={"large"}>
						Cancel
					</Button>
					<Button
						className="w-full"
						type={"primary"}
						size={"large"}
						onClick={handleDelete}
					>
						{loading_lead ? (
							<ScaleLoader
								color={"#ffffff"}
								loading={loading_lead}
								size={100}
								width={5}
								margin={3}
								height={15}
							/>
						) : (
							"Submit"
						)}
					</Button>
				</div>
			</div>
		</CustomStyledModal>
	);
};

const CustomStyledModal = styled(Modal)`
	.border-b {
		border-bottom: 1px solid #e4e7ec;
	}

	.border-cancel {
		border: 1px solid #e27626;
	}
`;

const mapStateToProps = ({ loading_lead, user }) => ({ loading_lead, user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal);
