import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { navLinks } from "../../helpers/data";
import logo from "../../assets/go-logo.svg";

const CampaignNavbar = () => {
	const [activeNav, setActiveNav] = useState(false);
	const [scrolling, setScrolling] = useState(false);

	const { width } = useWindowSize();
	const navigate = useNavigate();
	const pathname = useLocation().pathname;

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", () => setScrolling(window.pageYOffset > 100));
		}
		return () =>
			window.removeEventListener("scroll", () => setScrolling(window.pageYOffset > 100));
	}, []);

	return (
		<div
			className={`fixed top-0 left-0 right-0 z-10 duration-300 transition-colors ${
				scrolling ? "bg-white shadow" : "bg-transparent"
			}`}
		>
			<Inner>
				<NavLink to={"/"}>
					<img src={logo} alt={"logo"} className="max-h-8" />
				</NavLink>
				<div className="flex items-center gap-10">
					{width > 730 && (
						<div className="flex items-center gap-10 list-none">
							{navLinks.slice(3).map(({ name, path }, i) => (
								<NavLink
									key={i}
									to={path}
									className={`hover:text-primary ${
										path === pathname ? "text-primary" : "text-black"
									}`}
								>
									{name}
								</NavLink>
							))}
						</div>
					)}
					{width > 730 && (
						<Actions>
							<Button onClick={() => navigate("/contact")} type="primary">
								Contact Us
							</Button>
						</Actions>
					)}
				</div>
				{width < 730 && (
					<Burger onClick={() => setActiveNav((activeNav) => !activeNav)}>
						<div className={`${activeNav ? "rotate1 one" : "one"} bg-black`}></div>
						<div className={`${activeNav ? "rotate2 two" : "two"} bg-black`}></div>
						<div className={`${activeNav ? "rotate3 three" : "three"} bg-black`}></div>
					</Burger>
				)}
				{
					<div
						className={activeNav ? "navOpen mobile" : "mobile"}
						onClick={() => setActiveNav(false)}
					>
						{navLinks.slice(3).map(({ name, path }, i) => (
							<NavLink key={i} to={path}>
								{name}
							</NavLink>
						))}

						<Button type={"primary"} onClick={() => navigate("/contact")}>
							Contact Us
						</Button>
					</div>
				}
			</Inner>
		</div>
	);
};

const Inner = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	height: 100%;
	margin: 0 auto;
	padding: 18px 0px;

	a {
		text-decoration: none;
	}

	button {
		border-radius: 5px;
	}

	.mobile {
		background: ${({ theme }) => theme.colors.first};
		height: 90vh;
		width: 100%;
		position: fixed;
		margin: 0;
		top: 10vh;
		right: 100%;
		transition: transform 0.5s ease;
		overflow: hidden;
		z-index: 10;
		padding: 50px 30px;

		display: flex;
		flex-direction: column;
		align-items: flex-start;

		a {
			font-weight: 550;
			color: ${({ theme }) => theme.colors.secondary};
			font-size: 18px;
			margin-bottom: 30px;
		}

		button {
			margin-top: 30px;
			width: 100%;
			height: 50px;
			border-radius: 5px;
			font-weight: bold;
			box-sizing: border-box;
			font-size: 18px;
		}

		.btn-signup {
			color: ${({ theme }) => theme.colors.primary};
			border: 1px solid ${({ theme }) => theme.colors.primary};
		}

		.btn-signup:hover {
			background: ${({ theme }) => theme.colors.first};
		}
	}

	.navOpen {
		transform: translateX(100%);
	}
`;

const Actions = styled.div`
	display: flex;
	align-items: center;
`;

const Burger = styled.div`
	//position: relative;
	cursor: pointer;

	div {
		border-radius: 1px;
		display: block;
		transition: all 0.2s ease;
	}
	.one {
		padding: 1.3px 7px;
		width: 60%;
		margin-bottom: 0.3rem;
	}

	.three {
		padding: 1.3px 13px;
		width: 100%;
	}

	.two {
		padding: 1.3px 13px;
		width: 100%;
		margin-bottom: 0.3rem;
	}

	.rotate1 {
		transform: rotateZ(40deg) translateY(400%);
		width: 100%;
	}
	.rotate2 {
		opacity: 0;
	}
	.rotate3 {
		transform: rotateZ(-40deg) translateY(-400%);
	}
`;
export default CampaignNavbar;
