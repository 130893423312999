import React from "react";
import { NavLink } from "react-router-dom";
import { navLinks } from "../../helpers/data";

const CampaignFooter = () => {
	const year = new Date().getFullYear();

	const socialLinks = [
		{
			path: "https://www.linkedin.com/company/go-truck/",
			title: "LinkedIn",
			icon: "/icons/linkedin-white.svg",
		},
		{
			path: "https://www.instagram.com/gotruck_io/",
			title: "Instagram",
			icon: "/icons/instagram-white.svg",
		},
		{
			path: "https://twitter.com/gotruck_io?t=_m_WKu28dnsGwSX3bM817A&s=09",
			title: "Twitter",
			icon: "/icons/twitter-white.svg",
		},
		{
			path: "https://www.facebook.com/gotruck.io",
			title: "Facebook",
			icon: "/icons/facebook-white.svg",
		},
	];

	return (
		<div className="bg-[#0D056A] py-10 sm:py-16 space-y-4 md:space-y-6">
			<div className="flex flex-wrap justify-center items-center gap-5 md:gap-10 list-none">
				{navLinks.map(({ name, path }, i) => (
					<NavLink key={i} to={path} className="hover:text-primary text-white">
						{name}
					</NavLink>
				))}
			</div>

			<div className="flex items-center justify-center md:gap-3">
				{socialLinks?.map(
					({ path, title, icon }, i) =>
						path && (
							<a
								key={title}
								title={title}
								target="_blank"
								href={path}
								className="mr-2 cursor-pointer md:mr-0"
								rel="noreferrer"
							>
								<img
									src={icon}
									alt={`gotruck ${title}`}
									className="transition-transform duration-300 hover:-translate-y-1 hover:scale-110 w-4"
								/>
							</a>
						),
				)}
			</div>

			<p className="text-center text-sm text-[#aaa7cb]">© Copyright {year} - Go Truck</p>
		</div>
	);
};

export default CampaignFooter;
