import { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Table, Pagination, Button } from "antd";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { paginate } from "../../utils/paginate";
import PlaceBidModal from "./PlaceBidModal";
import toast from "react-hot-toast";
import { BID_DECISION_OPTIONS } from "../../helpers/tripHelpers";
import { BID_STATE } from "../../constants/trip";
import BidStatus from "./BidStatus";
import AcceptBidModal from "./AcceptBidModal";
import CompanyDetailsModal from "../common/CompanyDetailsModal";

const itemRender = (current, type, originalElement) => {
	if (type === "prev") {
		return null;
	}
	if (type === "next") {
		return null;
	}
	return originalElement;
};

const BidsTable = (props) => {
	const [openPlaceBidModal, setOpenPlaceBidModal] = useState(false);
	const [openEditBidModal, setEditBidModal] = useState(false);
	const [acceptBidModal, setAcceptBidModal] = useState(false);
	const [openCompanyModal, setOpenCompanyModal] = useState(false);
	const [companyDetail, setCompanyDetail] = useState();
	const [data, setData] = useState({ bid_price: "" });
	const [editBidData, setEditBidData] = useState({ bid_price: "" });
	const [acceptBidData, setAcceptBidData] = useState();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(5); //show 5 bids per view

	const id = props.trip.id;

	const handlePageChange = (page) => {
		setPageNumber(page);
	};

	const closeBidModal = () => {
		setOpenPlaceBidModal(false);
		setData({ bid_price: "" });
	};

	const closeEditBidModal = () => {
		setEditBidModal(false);
		setEditBidData({ bid_price: "" });
	};

	const openEditBid = (record) => {
		setEditBidModal(true);
		setEditBidData({ bid_price: record?.bid_price, id: record.id });
	};

	const openCompany = (record) => {
		setOpenCompanyModal(true);
		setCompanyDetail(record);
	};
	const closeCompany = () => {
		setOpenCompanyModal(false);
		setCompanyDetail();
	};

	const openAcceptBidModal = (data) => {
		const payload = {
			bid: data,
			trip: props.trip,
		};
		setAcceptBidModal(true);
		setAcceptBidData(payload);
	};
	const closeAcceptBidModal = () => {
		setAcceptBidModal(false);
		setAcceptBidData(null);
	};

	const placeNewBid = async () => {
		if (!data?.bid_price) return toast.error("Please enter bid amount");

		await props
			.createBid({ id, bid_price: data?.bid_price })
			.then((res) => {
				if (res?.error) {
					throw new Error(res?.error?.response?.data?.message);
				}
				toast.success("Bid placed successfully");
				props.getBids();
				closeBidModal();
			})
			.catch((error) => {
				toast.error(error.message || "An error occured, please try again");
				return error;
			});
	};

	const counterBid = async () => {
		if (!editBidData?.bid_price) return toast.error("Please enter bid amount");

		await props
			.counterBid({ tripId: id, bidId: editBidData?.id, bid_price: editBidData?.bid_price })
			.then((res) => {
				if (res?.error) {
					throw new Error(res?.error?.response?.data?.message);
				}
				toast.success("Bid countered successfully");
				props.getBids();
				closeEditBidModal();
			})
			.catch((error) => {
				toast.error(error.message || "An error occured, please try again");
				return error;
			});
	};

	useEffect(() => {
		id && props.isOpen && props.getBids(); //refetch bids when 'view bids' is open
	}, [id, props.isOpen]);

	const { PENDING, ACCEPTED, AWAITING_CONFIRMATION } = BID_STATE;

	const showBidActions = [PENDING, ACCEPTED, AWAITING_CONFIRMATION];

	const rejectBid = async (bidData) => {
		try {
			const res = await props.makeBidDecision({
				tripId: bidData?.tripId,
				bidId: bidData?.id,
				decision: BID_DECISION_OPTIONS.REJECT_BID,
			});
			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
			toast.success("Bid rejected successfully");
			props.getBids();
			return;
		} catch (e) {
			toast.error(e.message || "An error occured, please try again");
			return e;
		}
	};

	const columns = [
		{ title: "Bidding Date", dataIndex: "date", key: "date" },
		{
			title: "Haulage Company",
			dataIndex: "company",
			key: "company",
			render: (text, record) => (
				<p
					className="cursor-pointer hover:text-primary m-0 p-0 w-fit"
					onClick={() => openCompany(record?.companyDetails)}
				>
					{text}
				</p>
			),
		},
		{ title: "Haulage Company Offer", dataIndex: "rate", key: "rate" },
		{ title: "Your Counter Offer", dataIndex: "counter_offer", key: "counter_offer" },
		{ title: "Charges & VAT", dataIndex: "charges", key: "charges" },
		{
			title: "Status",
			key: "status",
			dataIndex: "status",
			render: (text) => <BidStatus key={text} status={text} />,
		},
		{
			title: "Action",
			key: "id",
			dataIndex: "id",
			render: (text, record) =>
				showBidActions.includes(record.status) && !record?.is_counter ? (
					<div className="flex items-center gap-3 underline-offset-2 font-[500]">
						{record.status !== AWAITING_CONFIRMATION && (
							<>
								<span
									className="text-[#FF3A44] underline cursor-pointer"
									onClick={() => rejectBid(record)}
								>
									Reject Bid
								</span>
								<span
									className="text-[#0780C7] underline cursor-pointer"
									onClick={() => openEditBid(record)}
								>
									Counter Bid
								</span>
							</>
						)}
						<span
							className="text-[#58B689] underline cursor-pointer"
							onClick={() => openAcceptBidModal(record)}
						>
							{record.status === AWAITING_CONFIRMATION
								? "Confirm Acceptance"
								: "Accept Bid"}
						</span>
					</div>
				) : (
					"----"
				),
		},
	];

	let paginatedTrips = paginate(props.bids, pageNumber, pageSize);

	return (
		<>
			<TableContainer>
				<Table
					columns={columns}
					rowKey="id"
					dataSource={paginatedTrips}
					pagination={false}
				/>
			</TableContainer>

			{props.bids?.length > pageSize && (
				<PagContainer>
					<Button
						onClick={() => {
							if (pageNumber === 1) {
								return;
							}
							setPageNumber((pageNumber) => pageNumber - 1);
						}}
						style={{ opacity: pageNumber === 1 ? 0 : 1 }}
					>
						<AiOutlineArrowLeft className={"icon1"} />
						Previous
					</Button>
					<Pagination
						total={props.bids.length}
						itemRender={itemRender}
						showSizeChanger={false}
						current={pageNumber}
						pageSize={pageSize}
						onChange={handlePageChange}
					/>
					<Button
						onClick={() => {
							if (pageNumber >= props.bids.length / pageSize) {
								return;
							}
							setPageNumber((pageNumber) => pageNumber + 1);
						}}
						style={{
							opacity: pageNumber >= props.bids.length / pageSize ? 0 : 1,
						}}
					>
						Next
						<AiOutlineArrowRight className={"icon2"} />
					</Button>
				</PagContainer>
			)}

			<PlaceBidModal
				title="Place New Bid"
				description="Edit the previous bid you have placed. All carriers' will see the new bid. We will notify you through email for every carriers' bid. 
Select a Carrier from the Pending list"
				visible={openPlaceBidModal}
				closeModal={() => setOpenPlaceBidModal(false)}
				data={data}
				setData={setData}
				handleSubmit={placeNewBid}
				loading={props.create_bid_loading}
			/>

			<PlaceBidModal
				title="Counter Bid"
				description="You can now adjust your bid if the offer from a haulage company isn't satisfactory. Simply enter your revised bid amount below, and we will notify the haulage company."
				data={editBidData}
				setData={setEditBidData}
				visible={openEditBidModal}
				closeModal={closeEditBidModal}
				handleSubmit={counterBid}
				loading={props.counter_bid_loading}
			/>

			<AcceptBidModal
				data={acceptBidData}
				setData={setAcceptBidData}
				visible={acceptBidModal}
				closeModal={closeAcceptBidModal}
			/>

			<CompanyDetailsModal
				company={companyDetail}
				visible={openCompanyModal}
				closeModal={closeCompany}
			/>
		</>
	);
};

const TableContainer = styled.div`
	width: 98%;
	margin: auto;

	th.ant-table-cell {
		background: #f7f8fa;
		border-bottom: 1px solid #e4e7ec;
	}

	td.ant-table-cell {
		font-size: 12px;
	}

	.ant-table {
		margin-bottom: 0px;
	}

	.ant-table-row {
		cursor: default;
		background: #f7f8fa;
	}

	.ant-table-placeholder {
		cursor: default;
		background: #f7f8fa;
	}

	.ant-empty-description {
		color: #667085;
	}
`;

const PagContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		border-radius: 5px;
		display: flex;
		align-items: center;
	}

	.icon1 {
		margin-right: 5px;
	}
	.icon2 {
		margin-left: 5px;
	}

	@media (max-width: 550px) {
		button {
			display: none;
		}
	}
`;

const mapStateToProps = ({ counter_bid_loading, user }) => ({ counter_bid_loading, user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(BidsTable);
