import { useState } from "react";
import { Form, Input, Checkbox, Button } from "antd";
import ScaleLoader from "react-spinners/ScaleLoader";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { FormContainer } from "../../styles/form";
import AuthContainer from "./AuthContainer";
import { reduxFunctions } from "../../helpers/reduxHelper";
import toast from "react-hot-toast";
import { getUser } from "../../helpers/jwt";
import usePageTitle from "../../components/hooks/usePageTitle";

const Login = (props) => {
	const [data, setData] = useState({ email: "", password: "" });
	const navigate = useNavigate();
	const location = useLocation();
	const redirectPath = location.state?.path || "/app";

	usePageTitle("Sign In");

	const handleChange = ({ currentTarget: input }) => {
		const newData = { ...data };
		newData[input.name] = input.value;
		setData(newData);
	};
	const onFinish = async () => {
		// console.log(data)
		try {
			const res = await props.loginUser(data.email, data.password);
			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}

			if (res?.payload?.data?.user?.user_type !== "regular") {
				localStorage.removeItem("access_token");
				throw new Error("Invalid username or password");
			} else {
				toast.success((t) => <span>Logged in successfully!</span>, {});
				navigate(redirectPath);
			}

			return;
		} catch (e) {
			toast.error(
				(t) => (
					<span>
						{e.toString().split(":")[1]
							? e.toString().split(":")[1]
							: "Invalid username or password"}
					</span>
				),
				{},
			);
			return e;
		}
	};

	if (getUser()) {
		return <Navigate to="/app" state={{ path: location.pathname }} />;
	}

	return (
		<AuthContainer>
			<FormContainer>
				<h1>Sign in</h1>

				<Form layout={"vertical"} onFinish={onFinish}>
					<Form.Item
						label="Email"
						name="email"
						required={false}
						rules={[
							{ required: true, message: "Please input your email!" },
							{
								type: "email",
								message: "Please enter a valid email!",
							},
						]}
						className="input-item"
					>
						<Input
							name="email"
							value={data.email}
							onChange={handleChange}
							className="input"
							placeholder="Enter your username"
						/>
					</Form.Item>
					<Form.Item
						label="Password"
						name="password"
						required={false}
						rules={[{ required: true, message: "Please input your password!" }]}
						className="input-item"
					>
						<Input.Password
							name="password"
							value={data.password}
							onChange={handleChange}
							className="input"
							placeholder="Enter your password"
						/>
					</Form.Item>
					<div className={"remember"}>
						<Checkbox>Remember me</Checkbox>
						<Link
							to={"/forgot-password"}
							style={{ color: "#e27725", cursor: "pointer" }}
						>
							Forgot Password?
						</Link>
					</div>
					<Button type="primary" htmlType="submit">
						{props.loading_login ? (
							<ScaleLoader
								color={"#ffffff"}
								loading={props.loading_login}
								size={150}
								width={8}
								margin={5}
								height={30}
							/>
						) : (
							"Login"
						)}
					</Button>
				</Form>
				<p className={"action"}>
					Do you have an Account?{" "}
					{process.env.NODE_ENV == process.env.REACT_APP_ENVIRONMENT ? (
						<a onClick={() => toast.error("Coming soon!")}>Register</a>
					) : (
						<Link to={"/signup"}>Register</Link>
					)}
				</p>
			</FormContainer>
		</AuthContainer>
	);
};

const mapStateToProps = ({ user, loading_login }) => ({ user, loading_login });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
