import { useEffect } from "react";

const useClickOutside = (ref, handler, disabled) => {
	useEffect(() => {
		if (disabled) return;

		const listener = (event) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler(event);
			event.stopPropagation(); // for other click events
			event.preventDefault(); // for anchor tags
		};

		document.addEventListener("click", listener, { capture: true });
		return () => {
			document.removeEventListener("click", listener, {
				capture: true,
			});
		};
	}, [ref, handler, disabled]);
};

export default useClickOutside;
