import { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Table, Input, Pagination, Button } from "antd";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import usePageTitle from "../../components/hooks/usePageTitle";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const itemRender = (current, type, originalElement) => {
	if (type === "prev") {
		return null;
	}
	if (type === "next") {
		return null;
	}
	return originalElement;
};

const DraftTrips = (props) => {
	const [trips, setTrips] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(7);
	const [searchText, setSearchText] = useState("");
	const [filteredTrips, setFilteredTrips] = useState([]);

	const navigate = useNavigate();

	const handlePageChange = (page) => {
		setPageNumber(page);
	};

	const getDraftTrips = async () => {
		try {
			const response = await props.getDraftTrips();
			const trips = response?.payload?.data?.trips;
			let newTrips = [];
			if (trips) {
				trips.forEach((trip) => {
					const newTrip = {
						id: `${trip?._id}`,
						key: `${trip?._id}`,
						start_address: trip?.start_address || "N/A",
						end_address: trip?.end_address || "N/A",
						cargo_type: trip?.cargo_type === "CONTAINER" ? "Container" : "Loose Goods",
						tonnage: trip?.tonnage,
						date: moment(`${trip?.updated_at}`).format("MMMM Do YYYY, h:mma"),
					};
					newTrips.push(newTrip);
				});

				setTrips([...newTrips]);
			}
			if (response?.error) {
				throw new Error(response);
			}
		} catch (error) {
			return error;
		}
	};

	const deleteDraft = async (id) => {
		try {
			const response = await props.deleteDraft(id);

			if (response?.error) {
				throw new Error(response);
			}
			toast.success("Draft deleted successfully");
			getDraftTrips();
		} catch (error) {
			return error;
		}
	};

	const handleDelete = (id) => {
		confirmAlert({
			title: "Confirm Deletion",
			message: "Are you sure you want to delete this trip?. This action cannot be reversed.",
			buttons: [{ label: "Yes", onClick: () => deleteDraft(id) }, { label: "No" }],
		});
	};

	useEffect(() => {
		getDraftTrips();
	}, []);

	const columns = [
		{ title: "Last Updated", dataIndex: "date", key: "date" },
		{ title: "Cargo Type", dataIndex: "cargo_type", key: "cargo_type" },
		{ title: "Tonnage Size", dataIndex: "tonnage", key: "tonnage" },
		{ title: "Pick up Location", key: "start_address", dataIndex: "start_address" },
		{ title: "Drop off Location", key: "end_address", dataIndex: "end_address" },
		{
			title: "Action",
			key: "id",
			dataIndex: "id",
			render: (text, record) => (
				<div className="flex items-center gap-3 underline-offset-2 font-[500]">
					<span
						className="text-[#0780C7] underline cursor-pointer"
						onClick={() => navigate(`/app/request?id=${record?.id}&is_draft=true`)}
					>
						Edit
					</span>
					<span
						className="text-[#FF3A44] underline cursor-pointer"
						onClick={() => handleDelete(record?.id)}
					>
						Delete
					</span>
				</div>
			),
		},
	];

	usePageTitle("Draft Trips");

	useEffect(() => {
		const filteredTrips = trips?.filter((row) => {
			return (
				row.start_address?.toLowerCase().includes(searchText.toLowerCase()) ||
				row.end_address?.toLowerCase().includes(searchText.toLowerCase()) ||
				row.status?.toLowerCase().includes(searchText.toLowerCase())
			);
		});
		setPageNumber(1);
		setFilteredTrips(filteredTrips);
		return () => {
			setFilteredTrips(trips);
		};
	}, [searchText]);

	const mainData = searchText.length > 0 ? filteredTrips : trips;

	let paginatedTrips = paginate([...mainData].reverse(), pageNumber, pageSize);

	return (
		<StyledHistory>
			<Inner>
				<Head>
					<div>
						<h1 className="m-0">Draft Trips</h1>
						<p>Continue editing your saved trips to request for a truck</p>
					</div>
					<div className="input-filter">
						<Input
							className="input"
							size="large"
							placeholder="Search by location..."
							prefix={<SearchOutlined />}
							onChange={(e) => setSearchText(e.target.value)}
						/>
					</div>
				</Head>
				<Table columns={columns} dataSource={paginatedTrips} pagination={false} />
				{mainData?.length > 5 && (
					<PagContainer>
						<Button
							onClick={() => {
								if (pageNumber === 1) {
									return;
								}
								setPageNumber((pageNumber) => pageNumber - 1);
							}}
							style={{ opacity: pageNumber === 1 ? 0 : 1 }}
						>
							<AiOutlineArrowLeft className={"icon1"} />
							Previous
						</Button>
						<Pagination
							total={mainData.length}
							itemRender={itemRender}
							showSizeChanger={false}
							current={pageNumber}
							pageSize={pageSize}
							onChange={handlePageChange}
						/>
						<Button
							onClick={() => {
								if (pageNumber >= mainData.length / pageSize) {
									return;
								}
								setPageNumber((pageNumber) => pageNumber + 1);
							}}
							style={{ opacity: pageNumber >= mainData.length / pageSize ? 0 : 1 }}
						>
							Next
							<AiOutlineArrowRight className={"icon2"} />
						</Button>
					</PagContainer>
				)}
			</Inner>
		</StyledHistory>
	);
};

const StyledHistory = styled.div`
	padding: 40px 0;
`;

const Inner = styled.div`
	width: 90%;
	margin: 0 auto;

	th.ant-table-cell::before {
		display: none;
	}

	td.ant-table-cell {
		font-size: 12px;
	}

	.ant-table {
		margin-bottom: 30px;
	}

	.ant-pagination-item {
		border: none;
	}

	.ant-pagination-item-active {
		background: #feeee2;
	}

	.ant-table-row {
		cursor: pointer;
	}
`;

const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.input {
		width: 300px;
		height: 40px;
		border-radius: 5px;
	}

	button {
		height: 40px;
		border-radius: 5px;
		margin-left: 20px;
	}
	.input-filter {
		display: flex;
	}

	.filters {
		border: 1px solid #dedede;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		font-size: 15px;
		border-radius: 5px;
		height: 40px;
		width: 100px;
	}

	.icon {
		margin-right: 5px;
	}

	@media (max-width: 550px) {
		display: block;

		.input {
			width: 100%;
			margin-bottom: 10px;
		}
	}
`;

const PagContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		border-radius: 5px;
		display: flex;
		align-items: center;
	}

	.icon1 {
		margin-right: 5px;
	}
	.icon2 {
		margin-left: 5px;
	}

	@media (max-width: 550px) {
		button {
			display: none;
		}
	}
`;

const mapStateToProps = ({
	user,
	past_trips,
	trip,
	upcoming_trips,
	loading_trip,
	update_trip_loading,
}) => ({
	user,
	past_trips,
	trip,
	upcoming_trips,
	loading_trip,
	update_trip_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(DraftTrips);
