import { Button } from "antd";
import CampaignNavbar from "./CampaignNavbar";
import styled from "styled-components";
import { useState, useEffect } from "react";

const CampaignBanner = ({ scrollToForm }) => {
	const [currentTextIndex, setCurrentTextIndex] = useState(0);

	const texts = ["Request", "Manage", "Track", "Receive"];

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
		}, 2000);

		return () => clearInterval(timer);
	}, [texts.length]);

	return (
		<div
			className="min-h-screen md:h-screen md:max-h-[1000px] w-full  bg-[#FDFDF8]"
			// style={{
			// 	background: "url(/images/company-bg.jpeg)",
			// 	backgroundSize: "cover",
			// 	backgroundRepeat: "no-repeat",
			// }}
		>
			<CampaignNavbar />
			<div className="flex items-center h-full">
				<div className="w-[90%] mx-auto mt-10">
					<div className="w-full flex flex-col space-y-8 md:space-y-0">
						<div className="lg:hidden flex justify-start mt-5">
							<img
								src="/images/campaign/campaign-img-1.svg"
								alt="analytics"
								className="max-w-[300px]"
							/>
						</div>

						<div className="text-center w-full min-w-fit relative">
							<StyledParagraph>
								<p className="custom  my-0 text-base sm:text-xl font-[500] text-[#344055]">
									Are you a Goods Mover or a Truck Owner?
								</p>
							</StyledParagraph>

							<h1 className="mt-2 mb-4 md:mt-6 md:mb-8 2xl:mt-8 2xl:mb-10 text-[30px] sm:text-[40px] md:text-[60px] 2xl:text-[80px] leading-[40px] text-[#344055] font-bold">
								<span>
									{texts.map((text, index) => (
										<p
											key={index}
											className={`${
												index === currentTextIndex
													? "inline-block"
													: "hidden"
											}  my-0`}
										>
											{text}
										</p>
									))}
								</span>{" "}
								with ease
							</h1>

							<Button
								type="primary"
								size="large"
								style={{
									padding: "12px 32px",
									height: "auto",
								}}
								onClick={scrollToForm}
							>
								Get Started
							</Button>

							<img
								src="/images/campaign/campaign-img-1.svg"
								alt="analytics"
								className="absolute left-0 xl:ml-20 -top-[70%] hidden lg:block 2xl:w-[450px]"
							/>
							<img
								src="/images/campaign/campaign-img-2.svg"
								alt="analytics"
								className="absolute right-0 xl:mr-24 top-[60%] hidden lg:block 2xl:w-[450px]"
							/>
						</div>

						<div className="lg:hidden flex justify-end">
							<img
								src="/images/campaign/campaign-img-2.svg"
								alt="analytics"
								className=" max-w-[300px]"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CampaignBanner;

const StyledParagraph = styled.div`
	.custom::after {
		content: url(/images/campaign/text-motif.svg);
		display: inline-block;
		margin-left: -8px;
	}
`;
