import React from "react";
import { Upload, message } from "antd";
import { connect } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { reduxFunctions } from "../../helpers/reduxHelper";

const ImageUpload = ({
	data,
	loadingImage,
	uploadUrl,
	fileList,
	setUploadUrl,
	sets3ImageUrl,
	setImageUrl,
	setFileList,
	setLoadingImage,
	s3ImageUrl,
	getUploadUrl,
}) => {
	const beforeUpload = async (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG/PNG file!");
			return;
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error("Image must smaller than 2MB!");
			return;
		}
		await getUploadUrl(file.type)
			.then(async (res) => {
				let url = res.payload.data.upload.url;
				let path = res.payload.data.upload.path;
				setUploadUrl(url);
				sets3ImageUrl(path);
				return isJpgOrPng && isLt2M;
			})
			.catch((e) => {
				message.error("Image must smaller than 2MB!");
				return;
			});
	};

	const handleAvatarChange = (info) => {
		setImageUrl(null);
		setFileList([]);
		data.profile_picture = null;
		let file = info.file;
		if (file.status === "removed") {
			setImageUrl(null);
			setFileList([]);
		} else if (file.status === "uploading") {
			setFileList([file]);
			setLoadingImage(true);
		} else if (file.status === "done") {
			let url = s3ImageUrl;
			setFileList([
				{
					uid: "current",
					name: file.name,
					status: "done",
					url: url,
				},
			]);
			setImageUrl(url);
			setLoadingImage(false);
		}
	};

	const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
		const xhr = new XMLHttpRequest();
		// S3 requires PUT method!
		xhr.open("PUT", uploadUrl);
		xhr.onreadystatechange = async () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					// Calls the update prop
					setImageUrl(s3ImageUrl);
					onSuccess(null, file);
				} else {
					message.error("Upload Failed");
				}
			}
		};
		xhr.upload.onprogress = (e) => {
			if (e.lengthComputable) {
				onProgress({ percent: Math.round((e.loaded / e.total) * 100).toFixed(2) }, file);
			}
		};
		xhr.send(file);
	};

	const uploadButton = (
		<div>
			{loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Click to upload</div>
		</div>
	);

	return (
		<Upload
			name="avatar"
			// listType={listType}
			className="avatar-uploader"
			showUploadList={false}
			action={uploadUrl}
			customRequest={(options) => {
				handleUpload(options);
			}}
			fileList={fileList}
			beforeUpload={beforeUpload}
			onChange={handleAvatarChange}
			style={{ border: "none" }}
		>
			<p className="text-primary cursor-pointer font-medium"> Upload Profile Picture</p>
		</Upload>
	);
};

const mapStateToProps = ({ user, imageToken }) => ({ user, imageToken });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
