import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { FETCH_NOTIFICATIONS_SUCCESS } from "../../redux/constants";

const NotificationsDropdown = ({ notifications, readNotification }) => {
	const dispatch = useDispatch();

	const updatedNotificationsPayload = (item) => {
		return notifications.map((e) => (e._id === item._id ? item : e));
	};

	const markAsRead = async (item) => {
		if (item.status === "read") return;

		try {
			const response = await readNotification(item._id);
			if (response?.error) {
				throw new Error(response);
			}

			const updatedNotifications = updatedNotificationsPayload(
				response?.payload?.data?.notification,
			);
			const payload = { data: { notifications: updatedNotifications } };

			dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload });
		} catch (e) {
			return e;
		}
	};

	return (
		<Container className="flex flex-col absolute w-[400px] bg-white top-12 -right-10 min-h-[250px] max-h-[450px] overflow-y-auto">
			<h2 className="bg-[#fafafa] m-0 text-sm p-3">Your Notifications</h2>

			{notifications?.length === 0 ? (
				<div className="grid place-items-center flex-1">
					<p className="text-sm m-0 text-[#344054] text-center">No notifications</p>
				</div>
			) : (
				<ul className="space-y- px-3">
					{[...notifications]?.reverse()?.map((item) => (
						<li
							className="flex items-center gap-2 py-1.5 border-b cursor-pointer"
							key={item._id}
							onClick={() => markAsRead(item)}
						>
							<span className="min-w-fit">
								<svg
									width="8"
									height="8"
									viewBox="0 0 8 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle
										cx="4"
										cy="4"
										r="4"
										fill={item.status === "unread" ? "#58B689" : "#D0D5DD"}
									/>
								</svg>
							</span>

							<div className="space-y-1">
								<p
									className={`text-sm m-0 ${
										item.status === "unread"
											? "text-[#344054]"
											: "text-[#667085d7]"
									}`}
								>
									{item.body}
								</p>
								<p className="text-xs m-0 text-[#667085]">
									{moment(item.created_at).fromNow()}
								</p>
							</div>
						</li>
					))}
				</ul>
			)}
		</Container>
	);
};

const Container = styled.div`
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 5px;

	.border-b {
		border-bottom: 1px solid #e4e7ec;
	}
`;

const mapStateToProps = ({ notifications, readNotification }) => ({
	notifications,
	readNotification,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDropdown);
