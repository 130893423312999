import { connect } from "react-redux";
import { reduxFunctions } from "../../helpers/reduxHelper";
import toast from "react-hot-toast";
import ScaleLoader from "react-spinners/ScaleLoader";
import currency from "currency.js";
import { Button, Collapse, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const { Panel } = Collapse;

const UnpaidInvoiceModal = (props) => {
	const navigate = useNavigate();
	const trips = props.unpaid_trips;

	const tripIds = trips?.map((e) => e.trip_id);
	const amountToPay = trips?.reduce((acc, val) => acc + val.price, 0);

	const activeCard = props.cards?.find((e) => e.active);

	const payForUnpaidTrips = async () => {
		try {
			const res = await props.payForUnpaidTrips({
				card_id: activeCard?._id,
				trips: tripIds,
			});
			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
			toast.success("Payment Successful");
			props.setUnpaidInvoiceVisible(false);
		} catch (e) {
			toast.error(e?.message || "An error occured");
			return e;
		}
	};

	const handleLogout = () => {
		localStorage.removeItem("access_token");
		navigate("/login", { replace: true });
	};

	return (
		<Modal visible={props.visible} width="750px" centered footer="" closable={false}>
			<div className="flex flex-col items-center text-center rounded-lg  sm:p-6 md:pb-6 relative">
				<div>
					<h1 className="m-0 mb-1">Attention Required</h1>
					<p className="text-[#344054] m-0">
						This account contains one or more pending payments. Please pay your unpaid
						invoice(s) to avoid any disruption to your account.
					</p>
				</div>

				<img src="/images/unpaid-invoice.png" alt="" className="max-w-[250px]" />

				<div>
					<p className="font-semibold text-2xl m-0">
						{currency(amountToPay).format({ symbol: "₦" })}
					</p>
					{activeCard ? (
						<div className="flex flex-col items-center gap-2">
							<Button
								type="primary"
								size="large"
								onClick={props.loading_pay_unpaid_trips ? null : payForUnpaidTrips}
								className="mt-4 w-48 disabled:bg-primary disabled:text-white"
							>
								{props.loading_pay_unpaid_trips ? (
									<ScaleLoader
										color="#ffffff"
										loading={props.loading_pay_unpaid_trips}
										size={10}
										width={4}
										margin={2}
										height={12}
									/>
								) : (
									"Pay Now"
								)}
							</Button>
							<button
								onClick={() => navigate("/app/settings?tab=payment")}
								className="border-none bg-[#fff] cursor-pointer text-primary text-sm underline underline-offset-2"
							>
								Update Payment Method
							</button>
						</div>
					) : (
						<button
							onClick={() => props.openCardModal()}
							className="border-none bg-[#fff] cursor-pointer text-primary text-base underline underline-offset-2"
						>
							Add Card
						</button>
					)}
				</div>

				<Collapse
					bordered={false}
					expandIconPosition="right"
					style={{ width: "100%", marginTop: "20px", background: "none", padding: "0px" }}
				>
					<Panel header="Unpaid Trip Summary" key="1" style={{ fontWeight: "500" }}>
						<div className="text-left font-normal text-xs">
							{trips?.map((e) => (
								<div
									key={e?.trip_id}
									className="py-2 space-y-1"
									style={{ borderTop: "1px solid #eaeaea" }}
								>
									<div className="grid grid-cols-12">
										<p className="col-span-3 m-0">Pickup Location:</p>
										<p className="col-span-9 m-0">{e?.start_address}</p>
									</div>
									<div className="grid grid-cols-12">
										<p className="col-span-3 m-0">Drop-off Location:</p>
										<p className="col-span-9 m-0">{e?.end_address}</p>
									</div>
									<div className="grid grid-cols-12">
										<p className="col-span-3 m-0">Pickup Date:</p>
										<p className="col-span-9 m-0">
											{moment(`${e?.start_time}`).format(
												"MMMM Do YYYY, h:mma",
											)}
										</p>
									</div>
									<div className="grid grid-cols-12 text-primary font-medium">
										<p className="col-span-3 m-0">Outstanding Balance:</p>
										<p className="col-span-9 m-0">
											{currency(e?.price).format({ symbol: "₦" })}
										</p>
									</div>
								</div>
							))}
						</div>
					</Panel>
				</Collapse>

				<div className="flex justify-end w-full">
					<button
						onClick={handleLogout}
						className="mt-2 border-none bg-[#fff] cursor-pointer text-primary text-sm"
					>
						Log Out
					</button>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = ({ unpaid_trips, loading_pay_unpaid_trips }) => ({
	unpaid_trips,
	loading_pay_unpaid_trips,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidInvoiceModal);
