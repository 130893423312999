import { useState } from "react";
import { connect } from "react-redux";
import OTPInput from "otp-input-react";
import locked from "../../assets/locked.jpg";
import { Inner, OTPContainer } from "../../styles/verification";

import { Button, Modal } from "antd";
import { reduxFunctions } from "../../helpers/reduxHelper";
import toast from "react-hot-toast";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const VerificationModal = (props) => {
	const delay = 300; // 300 seconds = 5 minutes
	const [otp, setOtp] = useState("");
	// Set initial state for time left and button enabled status
	const [timeLeft, setTimeLeft] = useState(0);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const timerIdRef = useRef(null);

	const navigate = useNavigate();

	const verifyAccount = async () => {
		try {
			const res = await props.verifyUser(otp);

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}

			toast.success("Account verified successfully");

			props.closeModal();
			props.openVerifiedModal();
			return;
		} catch (e) {
			toast.error(
				(t) => (
					<span>
						{e.toString().split(":")[1]
							? e.toString().split(":")[1]
							: "An error occured, please try again"}
					</span>
				),
				{},
			);
			return e;
		}
	};

	const resetTimer = () => {
		clearInterval(timerIdRef.current);
		setTimeLeft(delay);
		setButtonEnabled(false);
		timerIdRef.current = setInterval(() => {
			setTimeLeft((prevTime) => {
				if (prevTime > 1) {
					return prevTime - 1;
				} else {
					setButtonEnabled(true);
					clearInterval(timerIdRef.current);
					return 0;
				}
			});
		}, 1000);
	};

	const handleResend = async () => {
		try {
			const res = await props.resendVerificationCode();

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}

			toast.success("Code sent successfully");
			resetTimer();

			return;
		} catch (e) {
			toast.error(
				(t) => (
					<span>
						{e.toString().split(":")[1]
							? e.toString().split(":")[1]
							: "An error occured, please try again"}
					</span>
				),
				{},
			);
			return e;
		}
	};

	useEffect(() => {
		// Function to update the timer
		const tick = () => {
			setTimeLeft((prevTime) => {
				if (prevTime > 1) {
					return prevTime - 1;
				} else {
					setButtonEnabled(true);
					clearInterval(timerIdRef.current);
					return 0;
				}
			});
		};

		// Start the timer
		timerIdRef.current = setInterval(tick, 1000);

		// Clean up the interval on component unmount
		return () => clearInterval(timerIdRef.current);
	}, []);

	// Function to format time left as mm:ss
	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
	};

	const handleLogout = () => {
		localStorage.removeItem("access_token");
		navigate("/login", { replace: true });
	};

	return (
		<Modal
			visible={props.visible}
			width={650}
			centered
			footer={""}
			closable={false}
			maskClosable={false}
			className="relative"
		>
			<Inner>
				<img src={locked} alt={"locked"} />
				<h1>Account Verification</h1>
				<p>
					We sent a verification code to your email and <br /> phone number. Enter below.
				</p>
				<OTPContainer>
					<OTPInput
						value={otp}
						onChange={setOtp}
						autoFocus
						OTPLength={6}
						otpType="number"
						disabled={false}
					/>
				</OTPContainer>
				<Button type="primary" onClick={verifyAccount} disabled={otp.length < 6}>
					{props.loading_verify_user ? (
						<ScaleLoader
							color={"#ffffff"}
							loading={props.loading_verify_user}
							size={100}
							width={8}
							margin={5}
							height={30}
						/>
					) : (
						"Verify"
					)}
				</Button>
				<p className="m-0">
					Didn't receive the code?{" "}
					<span
						className={`${
							buttonEnabled ? "text-primary cursor-pointer" : "cursor-not-allowed"
						}`}
						onClick={buttonEnabled && !props.resend_code_loading ? handleResend : null}
					>
						Resend{" "}
						{props.resend_code_loading && (
							<ScaleLoader
								color="#e27626"
								loading={props.loading_verify_user}
								size={25}
								width={1.5}
								height={10}
							/>
						)}
					</span>{" "}
					{timeLeft > 0 && `in ${formatTime(timeLeft)}`}
				</p>
			</Inner>

			<p
				className="m-0 absolute right-5 bottom-5 text-base text-primary font-medium cursor-pointer"
				onClick={handleLogout}
			>
				Log Out
			</p>
		</Modal>
	);
};

const mapStateToProps = ({ user, loading_verify_user, resend_code_loading }) => ({
	user,
	loading_verify_user,
	resend_code_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(VerificationModal);
