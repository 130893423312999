import { BID_STATE, TRIP_STATE } from "../constants/trip";

export const getProgress = (activeState) => {
	switch (activeState) {
		case TRIP_STATE.IDLE:
			return {
				percentage: 0,
				title: "IDLE",
			};
		case TRIP_STATE.PENDING:
			return {
				percentage: 0,
				title: "PENDING",
			};
		case TRIP_STATE.ACCEPTED:
			return {
				percentage: 0,
				title: "ACCEPTED",
			};
		case TRIP_STATE.PAYMENT_PENDING:
			return {
				percentage: 0,
				title: "PAYMENT PENDING",
			};
		case TRIP_STATE.CANCELLED:
			return {
				percentage: 0,
				title: "CANCELLED",
			};
		case TRIP_STATE.ON_ROUTE:
			return {
				percentage: 10,
				title: "ON ROUTE",
			};
		case TRIP_STATE.ARRIVED:
			return {
				percentage: 10,
				title: "ARRIVED",
			};
		case TRIP_STATE.IMPOUNDED:
			return {
				percentage: 10,
				title: "IMPOUNDED",
			};
		case TRIP_STATE.FLAGGED:
			return {
				percentage: 0,
				title: "FLAGGED",
			};
		case TRIP_STATE.TRIP_STARTED:
			return {
				percentage: 50,
				title: "TRIP STARTED",
			};
		case TRIP_STATE.AT_DESTINATION:
			return {
				percentage: 90,
				title: "AT DESTINATION",
			};
		case TRIP_STATE.OFFLOADING_STARTED:
			return {
				percentage: 95,
				title: "OFFLOADING STARTED",
			};
		case TRIP_STATE.OFFLOADING_ENDED:
			return {
				percentage: 98,
				title: "OFFLOADING ENDED",
			};
		case TRIP_STATE.ON_ROUTE_DROPOFF:
			return {
				percentage: 98,
				title: "ON ROUTE DROPOFF",
			};
		case TRIP_STATE.AT_DROPOFF:
			return {
				percentage: 99,
				title: "AT DROPOFF",
			};
		case TRIP_STATE.FINISHED:
			return {
				percentage: 100,
				title: "FINISHED",
			};
		default:
			return {
				percentage: 0,
				title: "IDLE",
			};
	}
};

export const getBidProgress = (activeState) => {
	switch (activeState) {
		case BID_STATE.PENDING:
			return {
				title: "Pending",
			};
		case BID_STATE.ACCEPTED:
			return {
				title: "Accepted",
			};
		case BID_STATE.REJECTED:
			return {
				title: "Rejected",
			};
		case BID_STATE.AWAITING_CONFIRMATION:
			return {
				title: "Accepted",
			};
		case BID_STATE.ACCEPTANCE_CONFIRMED:
			return {
				title: "Acceptance Confirmed",
			};

		default:
			return {
				title: "Pending",
			};
	}
};

export const BID_DECISION_OPTIONS = {
	ACCEPT_BID: "accept",
	REJECT_BID: "reject",
	CONFIRM_ACCEPTANCE: "confirm_acceptance",
};
