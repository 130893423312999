const BiddingCard = ({ title, desc, info, value, children, image, content, isActive }) => {
	const pStyle = "m-0 text-sm text-[#667085]";
	const h2Style = "m-0 text-base";

	return (
		<div
			className={`${
				isActive ? "bg-[#fff3e9]" : ""
			} p-4 border rounded-md cursor-pointer flex items-center justify-between gap-8`}
			style={{ border: "1px solid rgb(231, 231, 231)" }}
		>
			<div className="flex items-center gap-3">
				<img src={image} alt="icon" className="max-w-[50px] w-full" />

				<div>
					<h2 className={h2Style}>{title}</h2>
					<p className={pStyle}>{desc}</p>
				</div>
			</div>

			<div className="flex items-center gap-3 text-right">
				<div>
					{value && <h2 className={h2Style}>{value}</h2>}
					{info && <p className={pStyle}>{info}</p>}
					{content && content}
				</div>
				{children}
			</div>
		</div>
	);
};

export default BiddingCard;
