import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";
import { MdOutlineVerified } from "react-icons/md";
import ReviewsList from "./ReviewsList";
import ReactStars from "react-stars";
import { ScaleLoader } from "react-spinners";

const CompanyDetailsModal = ({
	visible,
	closeModal,
	company,
	company_loading,
	company_overview,
	fetchCompanyOverview,
}) => {
	const contactDetails = [
		{ icon: "/email-icon.svg", value: company?.email },
		{ icon: "/phone-icon.svg", value: company?.business_number },
		{
			icon: "/location-icon.svg",
			value: company?.head_office_address,
		},
	];

	const getCompanyOverview = async () => {
		try {
			const res = await fetchCompanyOverview(company?._id);

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
		} catch (e) {
			return e;
		}
	};

	const averageRating =
		company_overview?.ratings?.reduce((acc, val) => acc + val.rating, 0) /
			company_overview?.ratings?.length || 0;

	useEffect(() => {
		if (company?._id) getCompanyOverview();
	}, [company?._id]);

	return (
		<CustomStyledModal
			visible={visible}
			width={700}
			centered
			theme="dark"
			onCancel={closeModal}
			footer=""
		>
			{company_loading ? (
				<div className="h-[70vh] flex items-center justify-center">
					<ScaleLoader
						color={"#e27626"}
						loading={company_loading}
						size={150}
						width={8}
						margin={5}
						height={30}
					/>
				</div>
			) : !company_overview ? (
				<div className="flex flex-col items-center justify-center min-h-[30vh]">
					<p className="text-lg font-[500]">An error occured, please try again</p>

					<Button
						className="border-cancel w-[175px]"
						type="primary"
						onClick={getCompanyOverview}
						size={"large"}
					>
						Retry
					</Button>
				</div>
			) : (
				<div className="space-y-4">
					<div className="lg:p-2 rounded-lg flex flex-col md:flex-row items-center gap-4">
						<img
							src={
								company_overview?.profile_picture?.original ||
								"/default-company-icon.svg"
							}
							alt={company?.name}
							className="max-w-[80px] p-2 rounded-lg bg-[#fafafa]"
						/>

						<div className="space-y-1">
							<h3 className="text-lg font-semibold flex items-center gap-1 m-0 p-0">
								{company?.name}{" "}
								{company?.documents?.[company?.documents?.length - 1]?.status ===
									"VERIFIED" && <MdOutlineVerified className="text-primary" />}
							</h3>
							<div className="flex items-center gap-2">
								<p className="px-2.5 py-1.5 rounded-md text-white bg-[#E7B66B] m-0 w-fit">
									{averageRating.toFixed(1)}
								</p>
								<ReactStars
									value={averageRating}
									count={5}
									edit={false}
									size={24}
									color1="#F2F2F2"
									color2="#E7B66B"
								/>
								<p className="text-[#5465FF] text-xs m-0 w-fit">
									({company_overview?.ratings?.length} Reviews)
								</p>
							</div>
						</div>
					</div>

					<div className="flex flex-col md:flex-row justify-between gap-4">
						{company_overview?.bio && <p className="">{company_overview?.bio}</p>}

						<div className="space-y-2">
							{contactDetails?.map(
								({ icon, value }) =>
									value && (
										<div key={value} className="flex items-center gap-2">
											<img src={icon} alt={value} />

											<p className="m-0">{value}</p>
										</div>
									),
							)}
						</div>
					</div>

					<ReviewsList companyOverview={company_overview} averageRating={averageRating} />
				</div>
			)}
		</CustomStyledModal>
	);
};

const CustomStyledModal = styled(Modal)`
	.border-l {
		border-left: 1px solid #e4e7ec;
	}
	.border-t {
		border-top: 1px solid #e4e7ec;
	}
	.border-t-0 {
		border-top: 0px;
	}

	.border-cancel {
		border: 1px solid #e27626;
	}

	@media (max-width: 768px) {
		.border-l {
			border-left: none;
		}

		.border-t-1 {
			border-top: 1px solid #e4e7ec;
		}
	}
`;

const mapStateToProps = ({ bid_decision_loading, company_loading, company_overview }) => ({
	bid_decision_loading,
	company_loading,
	company_overview,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsModal);
