import { Navigate, useLocation } from "react-router-dom";
import { getUser } from "../../helpers/jwt";

const RequireAuth = ({ children }) => {
	const location = useLocation();

	if (!getUser()) {
		return <Navigate to="/login" state={{ path: location.pathname }} />;
	}
	return children;
};

export default RequireAuth;
