import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavDropdown = ({ setDropdown, userEmail }) => {
	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem("access_token");
		navigate("/login", { replace: true });
	};

	return (
		<Dropdown onClick={() => setDropdown(false)}>
			<li className="item">
				<Link to="settings?tab=profile" className="link profile-link">
					<p>{userEmail ? userEmail : "userID"}</p>
					<p className="p2">Edit profile</p>
				</Link>
			</li>
			<li className="item">
				<Link to="settings?tab=payment" className="link">
					Settings
				</Link>
			</li>
			<li className="item" onClick={handleLogout}>
				<div className="link">Logout</div>
			</li>
		</Dropdown>
	);
};

const Dropdown = styled.ul`
	position: absolute;
	width: 200px;
	list-style: none;
	background: white;
	top: 9vh;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 5px;

	.hidden {
		display: none;
	}

	.item {
		height: 55px;
		box-sizing: border-box;
		border-bottom: 1px solid #e0e0e0;

		p {
			font-size: 11px;
		}
	}

	.link {
		height: 100%;
		color: black;
		display: flex;
		align-items: center;
		font-size: 11px;
		padding: 0 20px;
		cursor: pointer;
	}

	.link:hover {
		background: #f5f5f5;
	}

	p {
		margin: 0;
		padding: 0;
	}

	.profile-link {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding: 0 20px;
		margin: 0;

		.p2 {
			font-style: italic;
			font-size: 8px;
		}
	}
`;

export default NavDropdown;
