import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Table, Input, Pagination, Button } from "antd";
import TripStatus from "../../components/common/TripStatus";
import TripSummaryModal from "../../components/common/TripSummaryModal";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import usePageTitle from "../../components/hooks/usePageTitle";
import { TRIP_STATE } from "../../constants/trip";
import ReviewTripModal from "../../components/common/ReviewTripModal";

const itemRender = (current, type, originalElement) => {
	if (type === "prev") {
		return null;
	}
	if (type === "next") {
		return null;
	}
	return originalElement;
};

const TripList = (props) => {
	const [visible, setVisible] = useState(false);
	const [trips, setTrips] = useState([]);
	const [upcomingTrips, setUpcomingTrips] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(7);
	const [trip, setTrip] = useState(props.trip);
	const [searchText, setSearchText] = useState("");
	const [filteredTrips, setFilteredTrips] = useState([]);
	const [reviewTrip, setReviewTrip] = useState(false);

	const openReviewTripModal = () => setReviewTrip(true);
	const closeReviewTripModal = () => setReviewTrip(false);

	const handlePageChange = (page) => {
		setPageNumber(page);
	};

	const { category } = useParams();

	const getPastTripsData = async () => {
		try {
			const response = await props.getPastTrips();
			const trips = response?.payload?.data?.trips;
			let newTrips = [];
			if (trips) {
				trips.forEach((trip) => {
					const newTrip = {
						id: `${trip?._id}`,
						key: `${trip?._id}`,
						start_address: trip?.start_address,
						end_address: trip?.end_address,
						container_id: trip?.container_number ? trip?.container_number : "N/A",
						status: trip?.state?.value,
						start_time: trip?.start_time,
						date: moment(`${trip?.start_time}`).format("MMMM Do YYYY, h:mma"),
					};
					newTrips.push(newTrip);
				});

				setTrips([...newTrips]);
			}
			if (response?.error) {
				throw new Error(response);
			}
		} catch (error) {
			return error;
		}
	};

	useEffect(() => {
		getPastTripsData();
	}, []);

	const getUpcomingTripsData = async () => {
		try {
			const response = await props.getUpcomingTrips();
			const trips = response?.payload?.data?.trips;
			let newTrips = [];
			if (trips) {
				trips.forEach((trip) => {
					const newTrip = {
						id: `${trip?._id}`,
						key: `${trip?._id}`,
						start_address: trip?.start_address,
						end_address: trip?.end_address,
						container_id: trip?.container_number ? trip?.container_number : "N/A",
						status: trip?.state?.value,
						payment: trip?.payment_link,
						start_time: trip?.start_time,
						date: moment(`${trip?.start_time}`).format("MMMM Do YYYY, h:mma"),
						bids: trip?.bids || [],
					};
					newTrips.push(newTrip);
				});

				setUpcomingTrips([...newTrips]);
			}
			if (response?.error) {
				throw new Error(response);
			}
		} catch (error) {
			return error;
		}
	};

	const { update_trip_loading } = props;

	useEffect(() => {
		getUpcomingTripsData();
	}, [update_trip_loading]);

	const getTrip = async (id) => {
		setVisible(true);
		try {
			const res = await props.getTrip(id);
			setTrip(res?.payload?.data?.trip);

			if (res?.error) {
				throw new Error(res);
			}
		} catch (error) {
			setVisible(false);
			return error;
		}
	};

	// const handleTripCancel = async (id) => {
	//   try {
	//     const res = await props.updateTripStatus(id, TRIP_STATE.CANCELLED);
	//     // console.log(res?.payload?.data);

	//     if (res?.error) {
	//       throw new Error(res);
	//     }
	//   } catch (error) {
	//     return error;
	//   }
	// };

	const columns = [
		{ title: "Container ID", dataIndex: "container_id", key: "container_id" },
		{ title: "Date", dataIndex: "date", key: "date" },
		{ title: "Pick up Location", key: "start_address", dataIndex: "start_address" },
		{ title: "Drop off Location", key: "end_address", dataIndex: "end_address" },
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => <TripStatus status={text} />,
		},
		{
			title: "Payment",
			dataIndex: "payment",
			key: "payment",
			render: (text, record) => {
				const startTime = record.start_time; // trip start time
				const currentTime = new Date();

				// Convert startTime to Date object
				const startTimeDate = new Date(startTime);

				return text &&
					record.status === TRIP_STATE.PAYMENT_PENDING &&
					startTimeDate > currentTime ? (
					<a
						href={text}
						target="_blank"
						rel="noreferrer"
						className="font-semibold bg-primary px-4 py-2 rounded-lg text-white min-w-fit whitespace-nowrap hover:text-white hover:bg-[#e27726b9] transition-colors duration-300"
						onClick={(e) => e.stopPropagation()}
					>
						Pay Now
					</a>
				) : (
					<p className="text-center">--</p>
				);
			},
		},
	];

	if (category !== "upcoming") {
		delete columns[5];
	}

	const allTrips = category ? (category === "upcoming" ? upcomingTrips : trips) : [];

	usePageTitle(`${category === "upcoming" ? "Upcoming Trips" : "Trip History"}`);

	useEffect(() => {
		const filteredTrips = allTrips?.filter((row) => {
			return (
				row.start_address.toLowerCase().includes(searchText.toLowerCase()) ||
				row.end_address.toLowerCase().includes(searchText.toLowerCase()) ||
				row.status.toLowerCase().includes(searchText.toLowerCase())
			);
		});
		setPageNumber(1);
		setFilteredTrips(filteredTrips);
		return () => {
			setFilteredTrips(allTrips);
		};
	}, [searchText]);

	const mainData = searchText.length > 0 ? filteredTrips : allTrips;

	let paginatedTrips = paginate(mainData, pageNumber, pageSize);

	return (
		<StyledHistory>
			<Inner>
				<Head>
					<h1>{category === "upcoming" ? "Upcoming Trips" : "Trip History"}</h1>
					<div className={"input-filter"}>
						<Input
							className={"input"}
							size="large"
							placeholder="Search"
							prefix={<SearchOutlined />}
							onChange={(e) => setSearchText(e.target.value)}
						/>
						<Button>Download all</Button>
					</div>
				</Head>
				<Table
					onRow={(record, rowIndex) => {
						return {
							onClick: (event) => {
								getTrip(record.id);
							},
						};
					}}
					columns={columns}
					dataSource={paginatedTrips}
					pagination={false}
				/>
				{mainData?.length > 5 && (
					<PagContainer>
						<Button
							onClick={() => {
								if (pageNumber === 1) {
									return;
								}
								setPageNumber((pageNumber) => pageNumber - 1);
							}}
							style={{ opacity: pageNumber === 1 ? 0 : 1 }}
						>
							<AiOutlineArrowLeft className={"icon1"} />
							Previous
						</Button>
						<Pagination
							total={mainData.length}
							itemRender={itemRender}
							showSizeChanger={false}
							current={pageNumber}
							pageSize={pageSize}
							onChange={handlePageChange}
						/>
						<Button
							onClick={() => {
								if (pageNumber >= mainData.length / pageSize) {
									return;
								}
								setPageNumber((pageNumber) => pageNumber + 1);
							}}
							style={{ opacity: pageNumber >= mainData.length / pageSize ? 0 : 1 }}
						>
							Next
							<AiOutlineArrowRight className={"icon2"} />
						</Button>
					</PagContainer>
				)}
			</Inner>

			<TripSummaryModal
				loading={props.loading_trip}
				visible={visible}
				closeModal={() => setVisible(false)}
				data={trip}
				ongoing={false}
				openReviewTripModal={openReviewTripModal}
			/>

			<ReviewTripModal visible={reviewTrip} closeModal={closeReviewTripModal} data={trip} />
		</StyledHistory>
	);
};

const StyledHistory = styled.div`
	padding: 40px 0;
`;

const Inner = styled.div`
	width: 90%;
	margin: 0 auto;

	th.ant-table-cell::before {
		display: none;
	}

	td.ant-table-cell {
		font-size: 12px;
	}

	.ant-table {
		margin-bottom: 30px;
	}

	.ant-pagination-item {
		border: none;
	}

	.ant-pagination-item-active {
		background: #feeee2;
	}

	.ant-table-row {
		cursor: pointer;
	}
`;

const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.input {
		width: 300px;
		height: 40px;
		border-radius: 5px;
	}

	button {
		height: 40px;
		border-radius: 5px;
		margin-left: 20px;
	}
	.input-filter {
		display: flex;
	}

	.filters {
		border: 1px solid #dedede;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		font-size: 15px;
		border-radius: 5px;
		height: 40px;
		width: 100px;
	}

	.icon {
		margin-right: 5px;
	}

	@media (max-width: 550px) {
		display: block;

		.input {
			width: 100%;
			margin-bottom: 10px;
		}
	}
`;

const PagContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		border-radius: 5px;
		display: flex;
		align-items: center;
	}

	.icon1 {
		margin-right: 5px;
	}
	.icon2 {
		margin-left: 5px;
	}

	@media (max-width: 550px) {
		button {
			display: none;
		}
	}
`;

const mapStateToProps = ({
	user,
	past_trips,
	trip,
	upcoming_trips,
	loading_trip,
	update_trip_loading,
}) => ({
	user,
	past_trips,
	trip,
	upcoming_trips,
	loading_trip,
	update_trip_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(TripList);
