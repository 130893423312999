import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Avatar, Button, Divider, Form, Input } from "antd";
import { connect } from "react-redux";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import ImageUpload from "../../../components/common/ImageUpload";
import ScaleLoader from "react-spinners/ScaleLoader";
import usePageTitle from "../../../components/hooks/usePageTitle";
import useWindowSize from "../../../hooks/useWindowSize";

const Profile = (props) => {
	const [data, setData] = useState(props.user);
	const [loadingImage, setLoadingImage] = useState(false);
	const [imageUrl, setImageUrl] = useState(null);
	const [uploadUrl, setUploadUrl] = useState(null);
	const [s3ImageUrl, sets3ImageUrl] = useState(null);
	const [fileList, setFileList] = useState([]);

	usePageTitle("Profile");

	const { width } = useWindowSize();

	const handleChange = ({ currentTarget: input }) => {
		const newData = { ...data };
		newData[input.name] = input.value;
		setData(newData);
	};

	const getUser = async () => {
		try {
			const response = await props.getMe();
			setData(response?.payload?.data?.user);

			if (response?.error) {
				throw new Error(response);
			}
		} catch (e) {
			console.log(e, "error");
		}
	};

	useEffect(() => {
		getUser();
	}, []);

	useEffect(() => {
		if (data.profile_picture) {
			setImageUrl(data.profile_picture.original);
			setFileList([
				{
					uid: "current",
					status: "done",
					url: data.profile_picture.original,
				},
			]);
		}
	}, []);

	const handleSave = async () => {
		const newData = { ...data };
		if (imageUrl) {
			newData.profile_picture = {
				original: imageUrl,
			};
		}
		try {
			const response = await props.updateUser(newData);
			// console.log(response);
			if (response?.error) {
				throw new Error(response?.error?.response?.data?.message);
			}
			toast.success(
				(t) => (
					<span>
						Successfully updated profile
						{/*<button onClick={() => toast.dismiss(t.id)}>Dismiss</button>*/}
					</span>
				),
				{},
			);
			return;
		} catch (e) {
			toast.error(
				(t) => (
					<span>
						{e.toString().split(":")[1]
							? e.toString().split(":")[1]
							: "Error updating your profile"}
					</span>
				),
				{},
			);
			return e;
		}
	};

	const inputStyle = { padding: "8px", borderRadius: "6px" };

	return (
		<div className="w-full xl:w-[80%] mx-auto">
			<div className="py-9 flex flex-col md:flex-row gap-12 md:gap-24 xl:gap-32">
				<div className="flex flex-col items-center gap-4 text-primary">
					<Avatar
						src={imageUrl || data?.profile_picture?.original || ""}
						shape="circle"
						size={width > 768 ? 240 : 150}
						className="w-48 h-48"
					/>
					<ImageUpload
						data={data}
						loadingImage={loadingImage}
						uploadUrl={uploadUrl}
						fileList={fileList}
						imageUrl={imageUrl}
						setUploadUrl={setUploadUrl}
						sets3ImageUrl={sets3ImageUrl}
						setImageUrl={setImageUrl}
						setFileList={setFileList}
						setLoadingImage={setLoadingImage}
						s3ImageUrl={s3ImageUrl}
						listType="picture-card"
						button={false}
					/>
				</div>

				<div className="w-full">
					<Form className="space-y-4 sm:space-y-6">
						<div name="given_name" className="w-full space-y-2">
							<label htmlFor="given_name" className="m-0">
								First Name
							</label>
							<Input
								name="given_name"
								size="large"
								value={data?.given_name}
								onChange={handleChange}
								placeholder="Enter your first name"
								style={inputStyle}
							/>
						</div>

						<Divider />

						<div label="Last Name" name="family_name" className="w-full space-y-2">
							<label htmlFor="given_name" className="m-0">
								Last Name
							</label>
							<Input
								name="family_name"
								size="large"
								value={data?.family_name}
								onChange={handleChange}
								placeholder="Enter your last name"
								style={inputStyle}
							/>
						</div>

						<Divider />

						<div name="phone_number" className="w-full space-y-2">
							<label htmlFor="phone_number">Phone Number</label>
							<Input
								name="phone_number"
								size="large"
								value={data?.phone_number}
								onChange={handleChange}
								placeholder="Enter your phone number"
								style={inputStyle}
							/>
						</div>

						<Divider />

						<div name="email" className="w-full space-y-2">
							<label htmlFor="email">Email</label>
							<Input
								name="email"
								size="large"
								value={data?.email}
								onChange={handleChange}
								placeholder="Enter your email"
								style={inputStyle}
								disabled
							/>
						</div>

						<div className="flex justify-end">
							<Button
								onClick={handleSave}
								type="primary"
								size="large"
								style={{ padding: "6px 32px", height: "auto", marginTop: "20px" }}
								// disabled={props.update_user_loading}
							>
								{props.update_user_loading ? (
									<ScaleLoader
										color={"#ffffff"}
										loading={props.update_user_loading}
										size={100}
										width={5}
										height={12}
									/>
								) : (
									"Save"
								)}
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({ user, update_user_loading }) => ({ user, update_user_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
