export function getKeyByValue(object, value) {
	for (const key in object) {
		if (object[key] === value) {
			return key;
		}
	}
	return null;
}

export function getFirstTwoInitials(name) {
	// Split the name into an array of words
	const nameParts = name.split(" ");
	// Initialize an empty array to hold the initials
	const initials = [];
	// Loop through the name parts and get the first character of each part
	for (let i = 0; i < nameParts.length; i++) {
		if (nameParts[i]) {
			initials.push(nameParts[i][0].toUpperCase());
		}
	}
	// Join the first two initials and return them
	return initials.slice(0, 2).join("");
}

export const processRatings = (ratings) => {
	const ratingCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

	ratings.forEach((rating) => {
		if (Number.isInteger(rating.rating) && rating.rating >= 1 && rating.rating <= 5) {
			ratingCounts[rating.rating]++;
		}
	});

	return ratingCounts;
};
