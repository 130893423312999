import React from "react";
import moment from "moment";
import styled from "styled-components";
import { getFirstTwoInitials, processRatings } from "../../helpers/utils";
import ReactStars from "react-stars";

const ReviewsList = ({ companyOverview, averageRating }) => {
	const ratingCounts = processRatings(companyOverview?.ratings);
	const totalRatings = companyOverview?.ratings.reduce((sum, item) => sum + item.rating, 0);
	const reviews = [...companyOverview?.ratings].reverse();

	return (
		<div className="max-h-[300px] overflow-y-auto drop-shadow-2xl">
			<div className="flex flex-col md:flex-row gap-4 md:gap-8 my-4">
				<div className="min-w-fit">
					<h3 className="text-sm font-semibold flex items-center gap-1 m-0 p-0 max-w-[200px]">
						{/* {`${companyOverview?.name}'s Reviews`} */}
						Go Truckers Reviews
					</h3>
					<p className="text-3xl font-extrabold m-0 p-0">{averageRating.toFixed(1)}</p>
					<ReactStars
						value={averageRating}
						count={5}
						edit={false}
						size={24}
						color1="#F2F2F2"
						color2="#E7B66B"
					/>
					<p className="text-[#858585] text-xs m-0 w-fit">({reviews?.length} Reviews)</p>
				</div>

				<div className="space-y-1 w-full mr-4">
					{Object.entries(ratingCounts)
						.reverse()
						.map(([stars, count]) => (
							<div key={stars} className="flex items-center">
								<p className="text-sm font-medium m-0">{stars} stars</p>
								<div className="flex-1 h-1.5 bg-[#F2F6FB] rounded-full overflow-hidden mx-2">
									<div
										className="h-full bg-[#E7B66B] rounded-full"
										style={{ width: `${(count / totalRatings) * 100}%` }}
									></div>
								</div>
								<p className="text-sm font-medium m-0">{count}</p>
							</div>
						))}
				</div>
			</div>

			{reviews?.map((item, i) => (
				<ReviewContainer key={i} className="py-3 space-y-1">
					<p className="m-0 p-0 text-xs">
						{moment(`${item?.created_at}`).format("MMMM Do, YYYY")}
					</p>
					<ReactStars
						value={item.rating}
						count={5}
						edit={false}
						size={24}
						color1="#F2F2F2"
						color2="#E7B66B"
					/>
					<div className="flex items-center gap-2">
						<div className="rounded-full w-8 h-8 bg-[#dde0ff] grid place-content-center text-[#5465FF]">
							{getFirstTwoInitials(
								`${item?.rater?.given_name} ${item?.rater?.family_name}`,
							)}
						</div>

						<p className="m-0">{`${item?.rater?.given_name} ${item?.rater?.family_name}`}</p>
					</div>
					<p className="m-0">{item?.comment}</p>
				</ReviewContainer>
			))}
		</div>
	);
};

export default ReviewsList;

const ReviewContainer = styled.div`
	border-bottom: 1px solid #f8f7f4;

	@media (max-width: 768px) {
	}
`;
