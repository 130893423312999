import { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Input, Pagination, Button } from "antd";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import currency from "currency.js";
import { TRIP_STATE } from "../../constants/trip";
import PendingBidsTableRow from "../../components/bidding/PendingBidsTableRow";

const itemRender = (current, type, originalElement) => {
	if (type === "prev") {
		return null;
	}
	if (type === "next") {
		return null;
	}
	return originalElement;
};

const PendingBids = (props) => {
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(7);
	const [searchText, setSearchText] = useState("");
	const [filteredTrips, setFilteredTrips] = useState([]);
	const [upcomingTrips, setUpcomingTrips] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(null);

	const handlePageChange = (page) => {
		setPageNumber(page);
	};

	const getUpcomingTripsData = async () => {
		try {
			const response = await props.getUpcomingTrips(0, TRIP_STATE.PENDING);
			const trips = response?.payload?.data?.trips.filter((e) => e.bids.length > 0);

			let newTrips = [];
			if (trips) {
				trips.forEach((trip) => {
					const newTrip = {
						id: `${trip?._id}`,
						key: `${trip?._id}`,
						start_address: trip?.start_address,
						end_address: trip?.end_address,
						drop_off_address: trip?.drop_off_address,
						container_id: trip?.container_number ? trip?.container_number : "N/A",
						status: trip?.state?.value,
						date: moment(`${trip?.start_time}`).format("MMM Do, YYYY"),
						bids: trip?.bids || [],
						rate: currency(trip?.cost_id?.actual_price).format({
							symbol: "₦",
						}),
					};
					newTrips.push(newTrip);
				});

				setUpcomingTrips([...newTrips]);
			}
			if (response?.error) {
				throw new Error(response);
			}
		} catch (error) {
			return error;
		}
	};

	useEffect(() => {
		getUpcomingTripsData();
	}, [props.update_trip_loading]);

	useEffect(() => {
		const filteredTrips = upcomingTrips?.filter((row) => {
			return (
				row.start_address.toLowerCase().includes(searchText.toLowerCase()) ||
				row.end_address.toLowerCase().includes(searchText.toLowerCase())
			);
		});
		setPageNumber(1);
		setFilteredTrips(filteredTrips);
		return () => {
			setFilteredTrips(upcomingTrips);
		};
	}, [searchText]);

	const mainData = searchText.length > 0 ? filteredTrips : upcomingTrips;

	let paginatedTrips = paginate(mainData, pageNumber, pageSize);

	return (
		<StyledHistory>
			<Inner>
				<Head>
					<div>
						<div className="flex items-center gap-3">
							<h1 className="m-0">Pending Bids</h1>
							{!!mainData?.length && (
								<p className="m-0 rounded-full px-2 py-1 bg-[#fff6f0] text-primary">
									{mainData?.length} Trips
								</p>
							)}
						</div>
						<p className="text-sm text-[#667085] font-normal">
							This is a list of offers. Click on view bids to see counter bids from
							haulage companies
						</p>
					</div>
					<div className={"input-filter"}>
						<Input
							className={"input"}
							size="large"
							placeholder="Search by location"
							prefix={<SearchOutlined />}
							onChange={(e) => setSearchText(e.target.value)}
						/>
					</div>
				</Head>

				<div className="grid grid-cols-[175px_1fr_1.5fr_1.5fr_90px_100px] gap-1.5 font-[500] bg-[#fafafa] p-4 my-4 border">
					<p className="m-0">Trip Date</p>
					<p className="m-0">Your Bid</p>
					<p className="m-0">Pick-up Location</p>
					<p className="m-0">Drop-off Location</p>
					<p className="m-0">Bids No.</p>
					<p className="m-0"></p>
				</div>

				{paginatedTrips?.length === 0 ? (
					<div className="flex flex-col items-center space-y-4 text-center p-12">
						<img src="/icons/empty.svg" alt="empty" className="w-[100px]" />
						<p className="font-[500]">No Data</p>
					</div>
				) : (
					<div className="mb-10">
						{paginatedTrips?.map((trip, index) => (
							<PendingBidsTableRow
								key={trip.id}
								trip={trip}
								index={index}
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
							/>
						))}
					</div>
				)}

				{mainData?.length > pageSize && (
					<PagContainer>
						<Button
							onClick={() => {
								if (pageNumber === 1) {
									return;
								}
								setPageNumber((pageNumber) => pageNumber - 1);
							}}
							style={{
								opacity: pageNumber === 1 ? 0 : 1,
							}}
						>
							<AiOutlineArrowLeft className={"icon1"} />
							Previous
						</Button>
						<Pagination
							total={mainData.length}
							itemRender={itemRender}
							showSizeChanger={false}
							current={pageNumber}
							pageSize={pageSize}
							onChange={handlePageChange}
						/>
						<Button
							onClick={() => {
								if (pageNumber >= mainData.length / pageSize) {
									return;
								}
								setPageNumber((pageNumber) => pageNumber + 1);
							}}
							style={{
								opacity: pageNumber >= mainData.length / pageSize ? 0 : 1,
							}}
						>
							Next
							<AiOutlineArrowRight className={"icon2"} />
						</Button>
					</PagContainer>
				)}
			</Inner>
		</StyledHistory>
	);
};

const StyledHistory = styled.div`
	padding: 40px 0;
`;

const Inner = styled.div`
	width: 90%;
	margin: 0 auto;

	.border {
		border-bottom: 1px solid #e4e7ec;
	}

	th.ant-table-cell::before {
		display: none;
	}

	td.ant-table-cell {
		font-size: 12px;
	}

	.ant-table {
		margin-bottom: 30px;
	}

	.ant-pagination-item {
		border: none;
	}

	.ant-pagination-item-active {
		background: #feeee2;
	}

	.ant-table-row {
		cursor: pointer;
	}
`;

const Head = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.input {
		width: 300px;
		height: 40px;
		border-radius: 5px;
	}

	button {
		height: 40px;
		border-radius: 5px;
		margin-left: 20px;
	}
	.input-filter {
		display: flex;
	}

	.filters {
		border: 1px solid #dedede;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		font-size: 15px;
		border-radius: 5px;
		height: 40px;
		width: 100px;
	}

	.icon {
		margin-right: 5px;
	}

	@media (max-width: 550px) {
		display: block;

		.input {
			width: 100%;
			margin-bottom: 10px;
		}
	}
`;

const PagContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		border-radius: 5px;
		display: flex;
		align-items: center;
	}

	.icon1 {
		margin-right: 5px;
	}
	.icon2 {
		margin-left: 5px;
	}

	@media (max-width: 550px) {
		button {
			display: none;
		}
	}
`;

const mapStateToProps = ({ trip, upcoming_trips, loading_trip, update_trip_loading }) => ({
	trip,
	upcoming_trips,
	loading_trip,
	update_trip_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PendingBids);
