import { ThemeProvider } from "styled-components";
import { Toaster } from "react-hot-toast";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./screens/landing/Home";
import Register from "./screens/authentication/Register";
import "./App.less";
import Login from "./screens/authentication/Login";
import AppHome from "./screens/application";
import RequireAuth from "./components/common/RequireAuth";
import Settings from "./screens/application/settings";
import Services from "./screens/landing/Services";
import Contact from "./screens/landing/Contact";
import Logout from "./screens/application/Logout";
import PriceEstimator from "./screens/application/PriceEstimator";
import TripList from "./screens/application/TripList";
import RequestTrip from "./screens/application/RequestTrip";
import ForgotPassword from "./screens/authentication/ForgotPassword";
import ResetPassword from "./screens/authentication/ResetPassword";
import AuthEstimator from "./screens/authentication/AuthEstimator";
import ScrollTop from "./components/common/ScrollTop";
import OngoingTrips from "./screens/application/OngoingTrips";
import AboutUs from "./screens/landing/AboutUs";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";
import AuthHome from "./screens/authentication";
import PartnerWithUs from "./screens/landing/PartnerWithUs";
import Quote from "./components/partner/Quote";
import TermsOfUse from "./screens/landing/TermsOfUse";
import PrivacyPolicy from "./screens/landing/PrivaryPolicy";
import SlAgreement from "./screens/landing/SlAgreement";
import Company from "./screens/landing/Company";
import PendingBids from "./screens/application/PendingBids";
import Campaign from "./screens/landing/Campaign";
import DraftTrips from "./screens/application/DraftTrips";

function App() {
	const theme = {
		colors: {
			primary: "#e27626",
			secondary: "#344054",
			first: "#fff5ee",
			second: "#65ffda",
			textOne: "#373737",
		},
	};
	return (
		<ThemeProvider theme={theme}>
			<Routes>
				<Route
					path="/app"
					element={
						<RequireAuth>
							{" "}
							<AppHome />{" "}
						</RequireAuth>
					}
				>
					<Route index element={<Navigate to={"request"} />} />
					<Route path="request" element={<RequestTrip />} />
					<Route path="ongoing-trips" element={<OngoingTrips />} />
					<Route path="settings" element={<Settings />} />
					<Route path="price-estimator" element={<PriceEstimator />} />
					<Route path="pending-bids" element={<PendingBids />} />
					<Route path="trips/drafts" element={<DraftTrips />} />
					<Route path="trips/:category" element={<TripList />} />
				</Route>

				<Route path="pwreset" element={<ResetPassword />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
				<Route path="signup" element={<Register />} />
				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />
				<Route path="campaign" element={<Campaign />} />

				<Route path={"/"} element={<AuthHome />}>
					<Route path="/" element={<Home />} />
					<Route path="about-us" element={<AboutUs />} />
					<Route path="company" element={<Company />} />
					<Route path="price-estimator" element={<AuthEstimator />} />
					<Route path="services" element={<Services />} />
					<Route path={"contact"} element={<Contact />} />
					<Route path={"partner"} element={<PartnerWithUs />} />
					<Route path={"quote"} element={<Quote />} />
					<Route path={"policy"} element={<PrivacyPolicy />} />
					<Route path={"terms"} element={<TermsOfUse />} />
					<Route path={"sla"} element={<SlAgreement />} />
				</Route>
			</Routes>
			<ScrollTop />
			<Toaster
				position="bottom-center"
				toastOptions={{
					// Define default options
					className: "",
					duration: 5000,
					style: {},

					// Default options for specific types
					success: {
						icon: <IoMdCheckmarkCircleOutline size={20} />,
						style: {
							background: "#1d8217",
							color: "#fff",
							border: "1px solid #1d8217",
						},
					},
					error: {
						icon: <BiErrorCircle size={20} />,
						style: {
							background: "#e61920",
							color: "#fff",
							border: "1px solid #e61920",
						},
					},
				}}
			/>
		</ThemeProvider>
	);
}

export default App;
