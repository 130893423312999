import React, { useState } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";
import ReactStars from "react-stars";
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import moment from "moment";

const ReviewTripModal = ({ visible, closeModal, data, loading_rating, rateTrip }) => {
	const [ratingData, setRatingData] = useState({ rating: 1, comment: "" });

	const submitRating = async () => {
		try {
			const res = await rateTrip({
				trip: data?._id,
				comment: ratingData?.comment,
				rating: ratingData?.rating,
			});

			if (res?.error) {
				throw new Error(res?.error?.response?.data?.message);
			}
			toast.success("Trip rated successfully. Thank you for using Go Truck.");
			setRatingData({ rating: 5, comment: "" });
			closeModal();
		} catch (e) {
			toast.error(e.message || "An error occured, please try again");
			return e;
		}
	};

	return (
		<CustomStyledModal
			visible={visible}
			width={500}
			centered
			theme="dark"
			onCancel={closeModal}
			footer=""
		>
			<div className="space-y-4 p-3">
				<h2 className="font-medium text-lg text-center">Thank you for using Go Truck.</h2>
				<p className="text-center text-sm text-[#344054]">
					Kindly rate your trip from{" "}
					<span className="font-medium">{data?.start_address}</span> to{" "}
					<span className="font-medium">{data?.end_address}</span> on the{" "}
					{moment(`${data?.start_time}`).format("Do of MMMM, YYYY")}.
				</p>

				<div className="flex justify-center">
					<ReactStars
						value={ratingData.rating}
						onChange={(rating) => setRatingData({ ...ratingData, rating })}
						count={5}
						size={48}
						color1="#F2F2F2"
						color2="#F1AC4D"
						half={false}
					/>
				</div>

				<div className="space-y-2 w-full">
					<label className="text-left">Leave a comment</label>
					<textarea
						rows={3}
						value={ratingData.comment}
						onChange={(e) => setRatingData({ ...ratingData, comment: e.target.value })}
						className="rounded-md w-full bg-[#F6F8F8] border-[#D4DEDC] outline-none p-3"
					/>
				</div>

				<Button
					type={"primary"}
					size="large"
					className="w-full rounded-md"
					onClick={submitRating}
				>
					{loading_rating ? (
						<ScaleLoader
							color={"#ffffff"}
							loading={loading_rating}
							size={100}
							width={5}
							margin={3}
							height={15}
						/>
					) : (
						"Submit"
					)}
				</Button>
			</div>
		</CustomStyledModal>
	);
};

const CustomStyledModal = styled(Modal)`
	.border-l {
		border-left: 1px solid #e4e7ec;
	}
	.border-t {
		border-top: 1px solid #e4e7ec;
	}
	.border-t-0 {
		border-top: 0px;
	}

	.border-cancel {
		border: 1px solid #e27626;
	}

	@media (max-width: 768px) {
		.border-l {
			border-left: none;
		}

		.border-t-1 {
			border-top: 1px solid #e4e7ec;
		}
	}
`;

const mapStateToProps = ({ loading_rating, rateTrip }) => ({ loading_rating, rateTrip });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ReviewTripModal);
