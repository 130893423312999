import styled from "styled-components";

export const Inner = styled.div`
	text-align: center;
	padding: 35px 0;
	overflow: auto;
	// height: 100vh;

	img {
		width: 150px;
		height: 150px;
		margin-bottom: 20px;
	}

	h1 {
		font-weight: bolder;
	}

	p {
		font-weight: 500;
		font-size: 18px;
		color: #9ea5b0;
	}

	button {
		width: 50%;
		border-radius: 5px;
		height: 60px;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 30px;
	}

	.resend {
		color: ${({ theme }) => theme.colors.primary};
		cursor: pointer;
	}

	input {
		padding: 5px 2px;
		font-weight: 600;
	}
`;

export const OTPContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 40px 0;

	input {
		border-radius: 5px;
		border: 1px solid #9ea5b0;
	}

	input:focus {
		outline: none;
	}
`;
