import React, { useState } from "react";
import { motion } from "framer-motion";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import BidsTable from "./BidsTable";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";
import refreshIcon from "../../assets/refresh-icon.svg";
import moment from "moment";
import currency from "currency.js";
import { serviceCharge } from "../../constants/trip";

const PendingBidsTableRow = ({ trip, index, currentIndex, setCurrentIndex, user, getTripBids }) => {
	const [numOfBids, setNumOfBids] = useState(
		trip.bids.filter((e) => (e.company_id || e.driver_id) && e.bid_creator !== user._id).length,
	);
	const [bids, setBids] = useState([]);
	const [refreshLoading, setRefreshLoading] = useState(false);

	const isOpen = currentIndex === index;

	const toggleBids = () => {
		setCurrentIndex(isOpen ? null : index);
	};

	const arrowIcon = isOpen ? <BiChevronDown /> : <BiChevronUp />;

	const getBids = async (refresh) => {
		try {
			if (refresh) setRefreshLoading(true);
			const response = await getTripBids(trip.id);
			setRefreshLoading(false);
			const rejectecBids = response?.payload?.data?.bids.filter(
				(e) =>
					(e.company_id || e.driver_id) &&
					e.accepted === false &&
					e.bid_creator._id !== user._id,
			);
			const otherBids = response?.payload?.data?.bids.filter(
				(e) =>
					(e.company_id || e.driver_id) &&
					e.accepted !== false &&
					(e.is_counter || e.bid_creator._id !== user._id),
			);

			const bidsArray = [...otherBids, ...rejectecBids]?.map((bid) => {
				const vat = () => {
					return (7.5 / 100) * (+bid?.bid_price + serviceCharge);
				};

				const charges = vat() + serviceCharge;
				return {
					date: moment(`${bid?.created_at}`).format("MMMM Do YYYY, h:mma"),
					rate: bid?.is_counter
						? "----"
						: currency(bid?.bid_price).format({ symbol: "₦" }),
					counter_offer: !bid?.is_counter
						? "----"
						: currency(bid?.bid_price).format({ symbol: "₦" }),
					bid_price: bid?.bid_price,
					id: bid?._id,
					tripId: bid?.trip?._id,
					accepted: bid?.accepted,
					tripState: bid?.trip?.state?.value,
					company: bid?.company_id?.name || bid?.driver_id?.full_name,
					companyDetails: bid?.company_id || bid?.driver_id,
					status: bid?.status,
					is_counter: bid?.is_counter,
					charges: currency(charges).format({ symbol: "₦" }),
				};
			});

			setBids(bidsArray);
			setNumOfBids((prev) => bidsArray.length);
			if (response?.error) {
				throw new Error(response);
			}
		} catch (error) {
			setRefreshLoading(false);
			return error;
		}
	};

	return (
		<>
			<div className="grid grid-cols-[175px_1fr_1.5fr_1.5fr_100px_100px] items-center gap-1.5 font-[500] px-4 py-5 text-xs border">
				<p className="m-0">{trip.date}</p>
				<p className="m-0">{trip.rate}</p>
				<p className="m-0">{trip.start_address}</p>
				<p className="m-0">{trip.end_address}</p>
				<p className="m-0 text-center">{numOfBids}</p>

				<div className="flex items-center gap-2">
					<p
						className="m-0 text-primary underline cursor-pointer flex items-center min-w-fit"
						onClick={toggleBids}
					>
						<span>{isOpen ? "Close" : "View"} Bids</span>{" "}
						<span className="text-base -mb-1.5">{arrowIcon}</span>
					</p>

					<img
						src={refreshIcon}
						alt={"logo"}
						title="Refresh Bids"
						className={`max-h-3 cursor-pointer ${refreshLoading ? "animate-spin" : ""}`}
						onClick={() => getBids("refresh")}
					/>
				</div>
			</div>

			<motion.div
				initial={false}
				className="overflow-hidden bg-[#f7f8fa] mb-2"
				animate={{ height: isOpen ? "auto" : 0 }}
				transition={{ duration: 0.3, ease: [0.7, 0, 0.3, 1] }}
			>
				<BidsTable
					trip={trip}
					id={trip.id}
					isOpen={isOpen}
					bids={bids}
					setNumOfBids={setNumOfBids}
					getBids={getBids}
				/>
			</motion.div>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(PendingBidsTableRow);
