export const ACCESS_TOKEN = "access_token";

//LOGIN
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

//LOGOUT
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";

//SIGNUP
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAIL = "SIGNUP_USER_FAIL";

//CHANGE PASSWORD
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

//GET CURRENT USER
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

//UPDATE USER
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

//GET UPLOAD URL
export const GET_UPLOAD = "GET_UPLOAD";
export const GET_UPLOAD_SUCCESS = "GET_UPLOAD_SUCCESS";
export const GET_UPLOAD_FAIL = "GET_UPLOAD_FAIL";

//SEND RESET PASSWORD LINK
export const PASSWORD_RESET_EMAIL = "PASSWORD_RESET_EMAIL";
export const PASSWORD_RESET_EMAIL_SUCCESS = " PASSWORD_RESET_EMAIL_SUCCESS";
export const PASSWORD_RESET_EMAIL_FAIL = "PASSWORD_RESET_EMAIL_FAIL";

//RESET PASSWORD
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "Redux/RESET_PASSWORD_FAIL";

//CREATE COST
export const CREATE_COST = "CREATE_COST";
export const CREATE_COST_SUCCESS = "CREATE_COST_SUCCESS";
export const CREATE_COST_FAIL = "CREATE_COST_FAIL";

//GET PAST TRIPS
export const GET_PAST_TRIPS = "GET_PAST_TRIPS";
export const GET_PAST_TRIPS_SUCCESS = "GET_PAST_TRIPS_SUCCESS";
export const GET_PAST_TRIPS_FAIL = "GET_PAST_TRIPS_FAIL";

//GET A TRIP
export const GET_TRIP = "GET_TRIP";
export const GET_TRIP_SUCCESS = "GET_TRIP_SUCCESS";
export const GET_TRIP_FAIL = "GET_TRIP_FAIL";

//CREATE TRIP
export const CREATE_TRIP = "CREATE_TRIP";
export const CREATE_TRIP_SUCCESS = "CREATE_TRIP_SUCCESS";
export const CREATE_TRIP_FAIL = "CREATE_TRIP_FAIL";

//TRACK TRIP
export const TRACK_TRIP = "TRACK_TRIP";
export const TRACK_TRIP_SUCCESS = "TRACK_TRIP_SUCCESS";
export const TRACK_TRIP_FAIL = "TRACK_TRIP_FAIL";

//GET ONGOING TRIPS
export const GET_ONGOING_TRIPS = "GET_ONGOING_TRIPS";
export const GET_ONGOING_TRIPS_SUCCESS = "GET_ONGOING_TRIPS_SUCCESS";
export const GET_ONGOING_TRIPS_FAIL = "GET_ONGOING_TRIPS_FAIL";

//GET UPCOMING TRIPS
export const GET_UPCOMING_TRIPS = "GET_UPCOMING_TRIPS";
export const GET_UPCOMING_TRIPS_SUCCESS = "GET_UPCOMING_TRIPS_SUCCESS";
export const GET_UPCOMING_TRIPS_FAIL = "GET_UPCOMING_TRIPS_FAIL";

//UPDATE TRIP STATUS
export const UPDATE_TRIP_STATUS = "UPDATE_TRIP_STATUS";
export const UPDATE_TRIP_STATUS_SUCCESS = "UPDATE_TRIP_STATUS_SUCCESS";
export const UPDATE_TRIP_STATUS_FAIL = "UPDATE_TRIP_STATUS_FAIL";

//GET TRIP TOKEN
export const GET_TRIP_TOKEN = "GET_TRIP_TOKEN";
export const GET_TRIP_TOKEN_SUCCESS = "GET_TRIP_TOKEN_SUCCESS";
export const GET_TRIP_TOKEN_FAIL = "GET_TRIP_TOKEN_FAIL";

//INITIATE PAYMENT
export const INITIATE_PAYMENT = "INITIATE_PAYMENT";
export const INITIATE_PAYMENT_SUCCESS = "INITIATE_PAYMENT_SUCCESS";
export const INITIATE_PAYMENT_FAIL = "INITIATE_PAYMENT_FAIL";

//CREATE CARD
export const CREATE_CARD = "CREATE_CARD";
export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS";
export const CREATE_CARD_FAIL = "CREATE_CARD_FAIL";

//GET CARD LIST
export const GET_CARDS = "GET_CARDS";
export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS";
export const GET_CARDS_FAIL = "GET_CARDS_FAIL";

//DELETE CARD
export const DELETE_CARD = "DELETE_CARD";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAIL = "DELETE_CARD_FAIL";

//SET PRIMARY CARD
export const SET_PRIMARY_CARD = "SET_PRIMARY_CARD";
export const SET_PRIMARY_CARD_SUCCESS = "SET_PRIMARY_CARD_SUCCESS";
export const SET_PRIMARY_CARD_FAIL = "SET_PRIMARY_CARD_FAIL";

//OTP VALIDATION
export const VALIDATE_ACCOUNT = "VALIDATE_ACCOUNT";
export const VALIDATE_ACCOUNT_SUCCESS = "VALIDATE_ACCOUNT_SUCCESS";
export const VALIDATE_ACCOUNT_FAIL = "VALIDATE_ACCOUNT_FAIL";

//VERIFY USER
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";

//RESEND CODE
export const RESEND_CODE = "RESEND_CODE";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_FAIL = "RESEND_CODE_FAIL";

//FETCH TERMINALS
export const FETCH_TERMINALS = "FETCH_TERMINALS";
export const FETCH_TERMINALS_SUCCESS = "FETCH_TERMINALS_SUCCESS";
export const FETCH_TERMINALS_FAIL = "FETCH_TERMINALS_FAIL";

//PARTNER LEADS
export const CREATE_PARTNER = "CREATE_PARTNER";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const CREATE_PARTNER_FAIL = "CREATE_PARTNER_FAIL";

//TRIP BID
export const CREATE_BID = "CREATE_BID";
export const CREATE_BID_SUCCESS = "CREATE_BID_SUCCESS";
export const CREATE_BID_FAIL = "CREATE_BID_FAIL";

export const COUNTER_BID = "COUNTER_BID";
export const COUNTER_BID_SUCCESS = "COUNTER_BID_SUCCESS";
export const COUNTER_BID_FAIL = "COUNTER_BID_FAIL";

export const FETCH_BIDS = "FETCH_BIDS";
export const FETCH_BIDS_SUCCESS = "FETCH_BIDS_SUCCESS";
export const FETCH_BIDS_FAIL = "FETCH_BIDS_FAIL";

export const BID_DECISION = "BID_DECISION";
export const BID_DECISION_SUCCESS = "BID_DECISION_SUCCESS";
export const BID_DECISION_FAIL = "BID_DECISION_FAIL";

//UNPAID TRIPS
export const GET_UNPAID_TRIPS = "GET_UNPAID_TRIPS";
export const GET_UNPAID_TRIPS_SUCCESS = "GET_UNPAID_TRIPS_SUCCESS";
export const GET_UNPAID_TRIPS_FAIL = "GET_UNPAID_TRIPS_FAIL";

export const PAY_UNPAID_TRIPS = "PAY_UNPAID_TRIPS";
export const PAY_UNPAID_TRIPS_SUCCESS = "PAY_UNPAID_TRIPS_SUCCESS";
export const PAY_UNPAID_TRIPS_FAIL = "PAY_UNPAID_TRIPS_FAIL";

export const SAVE_DRAFT = "SAVE_DRAFT";
export const SAVE_DRAFT_SUCCESS = "SAVE_DRAFT_SUCCESS";
export const SAVE_DRAFT_FAIL = "SAVE_DRAFT_FAIL";

export const GET_DRAFT_TRIPS = "GET_DRAFT_TRIPS";
export const GET_DRAFT_TRIPS_SUCCESS = "GET_DRAFT_TRIPS_SUCCESS";
export const GET_DRAFT_TRIPS_FAIL = "GET_DRAFT_TRIPS_FAIL";

export const RATE_TRIP = "RATE_TRIP";
export const RATE_TRIP_SUCCESS = "RATE_TRIP_SUCCESS";
export const RATE_TRIP_FAIL = "RATE_TRIP_FAIL";

export const FETCH_UNRATED_TRIP = "FETCH_UNRATED_TRIP";
export const FETCH_UNRATED_TRIP_SUCCESS = "FETCH_UNRATED_TRIP_SUCCESS";
export const FETCH_UNRATED_TRIP_FAIL = "FETCH_UNRATED_TRIP_FAIL";

export const FETCH_COMPANY_RATINGS = "FETCH_COMPANY_RATINGS";
export const FETCH_COMPANY_RATINGS_SUCCESS = "FETCH_COMPANY_RATINGS_SUCCESS";
export const FETCH_COMPANY_RATINGS_FAIL = "FETCH_COMPANY_RATINGS_FAIL";

export const UPDATE_TRIP = "UPDATE_TRIP";
export const UPDATE_TRIP_SUCCESS = "UPDATE_TRIP_SUCCESS";
export const UPDATE_TRIP_FAIL = "UPDATE_TRIP_FAIL";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAIL = "FETCH_NOTIFICATIONS_FAIL";

export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAIL = "READ_NOTIFICATION_FAIL";
