import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "antd";
import { ContentContainer } from "../../../styles/settings";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import ScaleLoader from "react-spinners/ScaleLoader";
import DeleteAccountModal from "../../../components/settings/DeleteAccountModal";

const AccountSettings = (props) => {
	const [openModal, setOpenModal] = useState(false);

	const openDeleteModal = () => setOpenModal(true);
	const closeDeleteModal = () => setOpenModal(false);

	return (
		<>
			<StyledChange>
				<div className="flex flex-col gap-4 sm:flex-row justify-between ism:tems-center">
					<div>
						<h2>Delete Account</h2>
						<p className="text-[#667085]">
							{" "}
							permanently delete your account and profile
						</p>
					</div>

					<Button
						onClick={openDeleteModal}
						type={"primary"}
						size={"large"}
						className="px-3 w-[500px]"
						style={{ width: "200px" }}
					>
						{props.change_password_loading ? (
							<ScaleLoader
								color={"#ffffff"}
								loading={props.change_password_loading}
								size={100}
								width={5}
								margin={3}
								height={15}
							/>
						) : (
							"Delete Account"
						)}
					</Button>
				</div>
			</StyledChange>

			<DeleteAccountModal visible={openModal} closeModal={closeDeleteModal} />
		</>
	);
};

const StyledChange = styled(ContentContainer)`
	.input-item {
		margin-bottom: 30px;
	}

	.input {
		width: 70%;
		//margin-left: 10%;
		border-radius: 5px;
		height: 50px;
	}

	label {
		width: 300px;
		font-size: 17px;
	}

	@media (max-width: 700px) {
		.input {
			width: 100%;
		}
	}
`;

const mapStateToProps = ({ user, change_password_loading }) => ({ user, change_password_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
