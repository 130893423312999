import React, { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button, Divider, Form, Input } from "antd";
import { ContentContainer, Head } from "../../../styles/settings";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import ScaleLoader from "react-spinners/ScaleLoader";
import AccountSettings from "./AccountSettings";

const ChangePassword = (props) => {
	const [data, setData] = useState({
		current_password: "",
		new_password: "",
		confirm_password: "",
	});

	const { current_password, new_password, confirm_password } = data;

	const handleChange = ({ currentTarget: input }) => {
		const newData = { ...data };
		newData[input.name] = input.value;
		setData(newData);
	};

	const hasSixCharacters = new_password?.trim()?.length >= 6;
	const hasUpperCase = /[A-Z]/.test(new_password);
	const hasLowerCase = /[a-z]/.test(new_password);
	const hasDigit = /\d/.test(new_password);
	const hasSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(new_password);

	const handleSubmit = async () => {
		if (current_password === new_password || current_password === confirm_password)
			return toast.error("New password cannot be the same with the old password");
		if (new_password !== confirm_password)
			return toast.error("Entered passwords are not the same");
		if (!hasSixCharacters) return toast.error("Password must contain at least 6 characters");
		if (!hasUpperCase)
			return toast.error("Password must contain at least 1 uppercase character");
		if (!hasLowerCase)
			return toast.error("Password must contain at least 1 lowercase character");
		if (!hasDigit) return toast.error("Password must contain at least 1 digit");
		if (!hasSpecialCharacter)
			return toast.error("Password must contain at least 1 special character");

		if (new_password === confirm_password) {
			try {
				const res = await props.changePassword(data.current_password, data.new_password);
				if (res?.error) {
					throw new Error(res?.error?.response?.data?.message);
				}
				setData({
					current_password: "",
					new_password: "",
					confirm_password: "",
				});
				toast.success((t) => <span>Successfully changed password</span>, {});
				return;
			} catch (e) {
				toast.error(
					(t) => (
						<span>
							{e.toString().split(":")[1]
								? e.toString().split(":")[1]
								: "Error changing password!"}
						</span>
					),
					{},
				);
				return e;
			}
		}
	};

	const handleCancel = () => {
		setData({
			current_password: "",
			new_password: "",
			confirm_password: "",
		});
	};

	return (
		<>
			<StyledChange>
				<Head>
					<h2>Change Password</h2>
					<div className={"btn-container"}>
						<Button onClick={handleCancel} type={""} size={"large"}>
							Cancel
						</Button>
						<Button
							onClick={handleSubmit}
							type={"primary"}
							size={"large"}
							className={"btn"}
						>
							{props.change_password_loading ? (
								<ScaleLoader
									color={"#ffffff"}
									loading={props.change_password_loading}
									size={100}
									width={5}
									margin={3}
									height={15}
								/>
							) : (
								"Save"
							)}
						</Button>
					</div>
				</Head>
				<Divider />
				<Form layout={"horizontal"}>
					<Form.Item
						label="Current Password"
						name="current_password"
						required={false}
						rules={[{ required: true, message: "Please input your current password!" }]}
						className="input-item"
					>
						<div>
							<Input.Password
								name="current_password"
								size={"large"}
								value={data.current_password}
								onChange={handleChange}
								className="input"
							/>
						</div>
					</Form.Item>
					<Form.Item
						label="New Password"
						name="new_password"
						required={false}
						rules={[{ required: true, message: "Please input your password!" }]}
						className="input-item"
					>
						<div>
							<Input.Password
								name="new_password"
								size={"large"}
								value={data.new_password}
								onChange={handleChange}
								className="input"
							/>
						</div>
					</Form.Item>
					<Form.Item
						label="Confirm Password"
						name="confirm_password"
						required={false}
						rules={[{ required: true, message: "Please input your password!" }]}
						className="input-item"
					>
						<div>
							<Input.Password
								name="confirm_password"
								size={"large"}
								value={data.confirm_password}
								onChange={handleChange}
								className="input"
							/>
						</div>
					</Form.Item>
					<div className={"btn-container-2"}>
						<Button onClick={handleCancel} type={""} size={"large"}>
							cancel
						</Button>
						<Button
							onClick={handleSubmit}
							type={"primary"}
							size={"large"}
							className={"btn"}
						>
							{props.change_password_loading ? (
								<ScaleLoader
									color={"#ffffff"}
									loading={props.change_password_loading}
									size={100}
									width={5}
									margin={3}
									height={15}
								/>
							) : (
								"Save"
							)}
						</Button>
					</div>
				</Form>
			</StyledChange>
			<AccountSettings />
		</>
	);
};

const StyledChange = styled(ContentContainer)`
	.input-item {
		margin-bottom: 30px;
	}

	.input {
		width: 70%;
		//margin-left: 10%;
		border-radius: 5px;
		height: 50px;
	}

	label {
		width: 300px;
		font-size: 17px;
	}

	@media (max-width: 700px) {
		.input {
			width: 100%;
		}
	}
`;

const mapStateToProps = ({ user, change_password_loading }) => ({ user, change_password_loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
