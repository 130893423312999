import React, { useRef } from "react";
import usePageTitle from "../../components/hooks/usePageTitle";
import CampaignBanner from "../../components/campaign/CampaignBanner";
import Statistics from "../../components/campaign/Statistics";
import CampaignFooter from "../../components/campaign/CampaignFooter";
import CampaignNewsLetter from "../../components/campaign/CampaignNewsLetter";
import CampaignForm from "../../components/campaign/CampaignForm";

const Campaign = () => {
	const formRef = useRef(null);
	usePageTitle("Campaign");

	const scrollToForm = () =>
		formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

	return (
		<>
			<CampaignBanner scrollToForm={scrollToForm} />
			<Statistics />
			<span ref={formRef} />
			<CampaignForm />
			<CampaignNewsLetter />
			<CampaignFooter />
		</>
	);
};

export default Campaign;
