import Modal from "react-awesome-modal";
import currency from "currency.js";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { getKeyByValue } from "../../helpers/utils";
import { VEHICLE_TYPES } from "../../constants/trip";

const EstimatorModal = ({ visible, closeModal, data }) => {
	return (
		<Modal
			visible={visible}
			width="600"
			height="700"
			effect="fadeInUp"
			onClickAway={closeModal}
		>
			<Inner>
				<Head>
					<h1>Trip Summary</h1>
					<AiOutlineClose size={21} onClick={closeModal} className={"icon"} />
				</Head>
				<Content>
					<Item>
						<p className={"p1"}>Pick up Location</p>
						<p className={"p2"}>{data.start_address}</p>
					</Item>
					<Item>
						<p className={"p1"}>Drop off Location</p>
						<p className={"p2"}>{data.end_address}</p>
					</Item>
					{!data?.is_container_owner && (
						<Item>
							<p className={"p1"}>Empties Drop off Location</p>
							<p className={"p2"}>{data.drop_off_address}</p>
						</Item>
					)}
					<Item>
						<p className={"p1"}>Trip Type</p>
						<p className={"p2"}>
							{data.trip_category === "TERMINAL_LOCATION"
								? "Terminal - Location"
								: "Location - Location"}
						</p>
					</Item>
					<Item>
						<p className={"p1"}>Container Ownership</p>
						<p className={"p2"}>{data.is_container_owner ? "Owned" : "Rented"}</p>
					</Item>
					<Item>
						<p className={"p1"}>Truck Type</p>
						<p className={"p2"}>{getKeyByValue(VEHICLE_TYPES, data?.item_size)}</p>
					</Item>
					<Last>
						<p className={"p1"}>Tonnage</p>
						<p className={"p2"}>{data.tonnage} tons</p>
					</Last>
				</Content>
				<Total>
					<h2>Estimated Total</h2>
					<div className={"prices"}>
						<h2>{currency(data.price).format({ symbol: "₦" })} - </h2>{" "}
						<h2>&nbsp;{currency(data.price_upper_bound).format({ symbol: "₦" })}</h2>
					</div>
				</Total>
				{/* <div className={"request-btn"}>
          <Button className={"button"} type={"primary"} size={"small"}>
            Request Trip
          </Button>
        </div> */}
			</Inner>
		</Modal>
	);
};

const Inner = styled.div`
	padding: 20px;
	overflow: auto;
	height: 90vh;

	.icon {
		cursor: pointer;
	}

	.request-btn {
		width: 90%;
		margin: 10px auto;
	}

	.button {
		width: 100%;
		font-weight: bold;
		font-size: 15px;
		height: 40px;
		border-radius: 5px;
		margin: 0 auto;
	}
`;

const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Head = styled(Flex)`
	margin: 20px 0;
	h1 {
		margin: 0;
	}
`;
const Content = styled.div`
	background: #fff6f0;
	border-radius: 5px;
	padding: 20px 10px;
	width: 90%;
	margin: 0 auto;
`;

const Item = styled(Flex)`
	align-items: flex-start;
	margin-bottom: 10px;

	.p1 {
		width: 60%;
	}

	.p2 {
		font-size: 12px;
		font-weight: 600;
		width: 40%;
		text-align: right;
	}

	@media (max-width: 500px) {
		.p1,
		.p2 {
			width: 50%;
		}
	}

	@media (max-width: 350px) {
		display: block;

		.p1,
		.p2 {
			width: 100%;
		}
	}
`;

const Last = styled(Item)`
	align-items: flex-start;
	margin-bottom: 0;
`;

const Total = styled(Flex)`
	width: 90%;
	margin: 20px auto;

	.prices {
		display: flex;
		align-items: center;

		h2 {
			font-size: 17px;
		}
	}

	h2 {
		color: ${({ theme }) => theme.colors.primary};
		font-weight: bold;
	}
`;

export default EstimatorModal;
