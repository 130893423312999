import { useState } from "react";
import { Button, Input } from "antd";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { ScaleLoader } from "react-spinners";
import styled from "styled-components";
import { reduxFunctions } from "../../../helpers/reduxHelper";
import LandingNavbar from "./LandingNavbar";
import { config } from "../../../config";

const Banner = (props) => {
	const [trackingId, setTrackingId] = useState("");

	const trackTrip = () => {
		props
			.trackTrip(trackingId)
			.then((res) => {
				if (res.payload.status !== 200) {
					throw new Error();
				}
				window.location.href = `${config.REACT_APP_GOTRUCK_TRACKING}/track/${trackingId}`;
			})
			.catch((err) => {
				toast.error("Tracking failed. Please confirm tracking ID");
			});
	};
	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			trackTrip();
		}
	};
	return (
		<Container image={"/images/background.jpeg"}>
			<LandingNavbar />
			<Content>
				<h1>Request, Manage, Track, Receive with Ease</h1>

				<div className={"input-container"}>
					<Input
						value={trackingId}
						onChange={(e) => setTrackingId(e.target.value)}
						onKeyPress={handleKeyPress}
						placeholder="Input your tracking ID"
					/>
					<Button
						type={"primary"}
						size={"large"}
						onClick={
							process.env.NODE_ENV == process.env.REACT_APP_ENVIRONMENT
								? () => toast.error("Coming soon!")
								: trackTrip
						}
					>
						{props.track_trip_loading ? (
							<ScaleLoader
								color={"#ffffff"}
								loading={props.track_trip_loading}
								size={50}
								width={5}
								margin={5}
								height={20}
							/>
						) : (
							"Track"
						)}
					</Button>
				</div>
			</Content>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100vh;
	max-height: 1000px;
	background: url(${({ image }) => image}) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`;

const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		width: 55%;
		text-align: center;
		line-height: normal;
		font-size: 50px;
		color: #fff;
		margin-bottom: 50px;
	}

	.input-container {
		background: #fff;
		box-sizing: border-box;
		padding: 10px;
		width: 50%;
		border-radius: 5px;
		display: flex;

		input {
			border: none;

			&:focus {
				border-color: inherit;
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}

		button {
			border-radius: 5px;
			width: 170px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	@media (max-width: 960px) {
		h1 {
			width: 80%;
			font-size: 40px;
		}
	}

	@media (max-width: 900px) {
		.input-container {
			width: 80%;
		}
	}

	@media (max-width: 450px) {
		h1 {
			font-size: 30px;
		}
	}
`;

const mapStateToProps = ({ user, loading_fetch_terminals, track_trip_loading }) => ({
	user,
	loading_fetch_terminals,
	track_trip_loading,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
