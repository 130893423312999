import React, { useState } from "react";
import { IoIosSend } from "react-icons/io";
import { config } from "../../config";
import toast from "react-hot-toast";
import axios from "axios";

const CampaignNewsLetter = () => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
	const emailValid = regex.test(email);

	const addContact = (e) => {
		e.preventDefault();
		if (!email || !emailValid) return toast.error("Please enter a valid email");
		setLoading(true);

		axios({
			url: "https://api.sendgrid.com/v3/marketing/contacts",
			headers: {
				Authorization: `Bearer ${config.REACT_APP_SENDGRID_API_KEY}`,
			},
			method: "PUT",
			data: {
				contacts: [
					{
						email: email,
					},
				],
			},
		})
			.then((res) => {
				setLoading(false);
				toast.success("Subscribed successfully");
				setEmail("");
			})
			.catch((e) => {
				setLoading(false);
				toast.error("An error occured");
			});
	};
	return (
		<div className="bg-[#FFF5EE] py-8 sm:py-10">
			<div className="w-[90%] mx-auto flex flex-col sm:flex-row  justify-center items-center gap-4 sm:gap-8">
				<p className="text-xl m-0">Newsletter</p>

				<form
					className="bg-white rounded-full max-w-[400px] w-full flex items-center shadow-sm"
					onSubmit={addContact}
				>
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						placeholder="Enter your email"
						className="p-4 w-full rounded-full outline-none border-none"
					/>

					<button
						type="submit"
						className="px-2 py-1 mr-2 min-w-fit rounded-full bg-primary border-none cursor-pointer"
					>
						<IoIosSend color="#fff" size={24} className="mt-1" />
					</button>
				</form>
			</div>
		</div>
	);
};

export default CampaignNewsLetter;
