import styled from "styled-components";
import { getBidProgress } from "../../helpers/tripHelpers";

const BidStatus = ({ status }) => {
	return <Status status={status}>{getBidProgress(status).title}</Status>;
};

const Status = styled.span`
  //display: flex;
  //align-items: center;
  width: min-content;
  padding: 6px 12px;
  text-align: center;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #047a48
  background: #f3f4f7;
  color: ${({ status }) => status === "pending" && "#cdaa7d"};
  color: ${({ status }) => status === "accepted" && "#197eb4"};
  color: ${({ status }) => status === "rejected" && "#b42219"};
  color: ${({ status }) => status === "acceptance_confirmed" && "#197eb4"};
  color: ${({ status }) => status === "awaiting_confirmation" && "#197eb4"};
  background: ${({ status }) => status === "pending" && "#fff8ef"};
  background: ${({ status }) => status === "accepted" && "#f3f8fd"};
  background: ${({ status }) => status === "awaiting_confirmation" && "#f3f8fd"};
  background: ${({ status }) => status === "acceptance_confirmed" && "#f3f8fd"};
  background: ${({ status }) => status === "rejected" && "#fdf3f3"};

  .space {
    margin: 0 5px;
  }
`;

export default BidStatus;
