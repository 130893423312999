import React from "react";

const Statistics = () => {
	const data = [
		{ label: "Active Shippers", value: "50k+" },
		{ label: "Registered Drivers", value: "630+" },
		{ label: "Haulage Companies", value: "1000+" },
		{ label: "Customer Service", value: "24/7" },
	];

	return (
		<div className="bg-[#0D056A] py-8 sm:py-10">
			<div className="w-[80%] mx-auto grid sm:grid-cols-2 md:grid-cols-4 gap-8 place-content-center">
				{data.map(({ label, value }) => (
					<div className="text-center space-y-1">
						<p className="text-white font-bold text-3xl sm;text-4xl m-0"> {value}</p>
						<p className="text-[#FAFAFAE5] m-0">{label}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default Statistics;
