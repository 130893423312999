import styled from "styled-components";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Input } from "antd";

const InputAddress = ({ placeholder, address, setAddress, setCoordinates }) => {
	const handleSelect = async (value) => {
		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);
		setAddress(value);
		setCoordinates(latLng);
	};

	return (
		<PlacesAutocomplete
			value={address}
			onChange={setAddress}
			onSelect={handleSelect}
			searchOptions={{ componentRestrictions: { country: ["ng"] } }}
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<div>
					<StyledInput
						{...getInputProps({
							placeholder: placeholder,
						})}
					/>
					<div>
						{loading && <div>Loading...</div>}
						{suggestions.map((suggestion) => {
							const style = suggestion.active
								? { backgroundColor: "#e27626", cursor: "pointer" }
								: { backgroundColor: "#ffffff", cursor: "pointer" };

							return (
								<div
									key={suggestion.placeId}
									{...getSuggestionItemProps(suggestion, { style })}
								>
									{suggestion.description}
								</div>
							);
						})}
					</div>
				</div>
			)}
		</PlacesAutocomplete>
	);
};

const StyledInput = styled(Input)`
	border-radius: 5px;
	height: 50px;
	margin-bottom: 10px;
`;

export default InputAddress;
