import { useState } from "react";
import styled from "styled-components";
import { reduxFunctions } from "../../helpers/reduxHelper";
import { connect } from "react-redux";
import { Button, Form, Input } from "antd";
import CustomSelect from "../common/Select";
import toast from "react-hot-toast";
import { ScaleLoader } from "react-spinners";

const CampaignForm = (props) => {
	const [data, setData] = useState({});

	const handleChange = ({ currentTarget: input }) => {
		const newData = { ...data };
		newData[input.name] = input.value;
		setData(newData);
	};

	const createLead = async (data) => {
		try {
			const res = await props.createLead(data);
			if (res?.error) {
				throw new Error("Opps, an error occured");
			}
			setData({});
			toast.success("Submitted successfully");
		} catch (e) {
			toast.error("Opps, an error occured");
		}
	};

	const handleSubmit = () => {
		if (
			!data?.first_name ||
			!data?.last_name ||
			!data?.phone_number ||
			!data?.email ||
			!data?.business_type
		)
			return toast.error("Please fill all required fields");

		const payload = {
			first_name: data?.first_name,
			last_name: data?.last_name,
			phone: data?.phone_number,
			email: data?.email,
			business_type: data?.business_type,
			lead_source: "Website/Campaign",
		};

		createLead(payload);
	};

	const options = [
		{ label: "I own a truck", value: "Truck Owner" },
		{ label: "I want to move goods", value: "Goods or Cargo Owner" },
	];

	return (
		<div className="bg-[#FDFDF8] py-16 lg:py-24">
			<div className="w-[90%] mx-auto flex gap-12 flex-col-reverse lg:flex-row">
				<img
					src="/images/campaign/campaign-form-bg.svg"
					alt="iphone"
					className="w-full rounded-xl hidden sm:block"
				/>

				<div className="w-full space-y-6">
					<h2 className="text-3xl m-0">
						Your journey to seamless cargo movement starts here with Go Truck
					</h2>

					<FormContainer>
						<Form layout={"vertical"} onFinish={() => {}}>
							<Form.Item
								label="First Name"
								required={false}
								rules={[
									{ required: true, message: "Please input your first name" },
								]}
								className="input-item"
							>
								<Input
									name="first_name"
									value={data.first_name}
									onChange={handleChange}
									className="input"
								/>
							</Form.Item>

							<Form.Item
								label="Last Name"
								required={false}
								rules={[{ required: true, message: "Please input your last name" }]}
								className="input-item"
							>
								<Input
									name="last_name"
									value={data.last_name}
									onChange={handleChange}
									className="input"
								/>
							</Form.Item>

							<Form.Item
								label="Phone Number"
								required={false}
								rules={[
									{ required: true, message: "Please input your phone number" },
									{
										type: "number",
										message: "Please enter a valid phone number",
									},
								]}
								className="input-item"
							>
								<Input
									name="phone_number"
									//  type="number"
									value={data.phone_number}
									onChange={handleChange}
									style={{ WebkitAppearance: "none", margin: 0 }}
									className="input"
								/>
							</Form.Item>

							<Form.Item
								label="Email Address"
								required={false}
								rules={[
									{ required: true, message: "Please input your email" },
									{
										type: "email",
										message: "Please enter a valid email",
									},
								]}
								className="input-item"
							>
								<Input
									name="email"
									value={data.email}
									onChange={handleChange}
									className="input"
								/>
							</Form.Item>

							<div className="mb-4">
								<label className="">
									Do you own a truck or want to move goods?
								</label>
								<div className="selectContainer2 mt-2 bg-white">
									<CustomSelect
										options={options}
										size="large"
										handleChange={(value) =>
											setData({ ...data, business_type: value })
										}
										value={data.business_type}
										bordered={false}
										className="w-full text-xs"
										style={{ fontSize: "14px", fontWeight: "400" }}
									/>
								</div>
							</div>

							<Button
								type="primary"
								htmlType="submit"
								size="large"
								onClick={handleSubmit}
							>
								{props?.loading_lead ? (
									<ScaleLoader
										color={"#ffffff"}
										loading={props?.loading_lead}
										size={100}
										width={5}
										margin={3}
										height={15}
									/>
								) : (
									"Submit"
								)}
							</Button>
						</Form>
					</FormContainer>
				</div>
			</div>
		</div>
	);
};

const FormContainer = styled.div`
	h4 {
		color: ${({ theme }) => theme.colors.primary};
		margin-bottom: 40px;
		font-size: 18px;
	}

	.input {
		height: 40px;
		border-radius: 5px;
	}

	.btn-container {
		display: flex;
		justify-content: center;
	}

	button {
		height: 40px;
		border-radius: 5px;
		width: 175px;
		margin-top: 20px;
	}

	.selectContainer2 {
		border: 1px solid #d0d5dd;
		border-radius: 5px;
	}
`;

const mapStateToProps = ({ loading_lead }) => ({ loading_lead });

const mapDispatchToProps = reduxFunctions;
export default connect(mapStateToProps, mapDispatchToProps)(CampaignForm);
